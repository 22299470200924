export enum ATTENDANCE_STATUS {
  ATTEND = 'ATTEND',
  ABSENT = 'ABSENT',
  LATE = 'LATE',
  EARLY_LEAVE = 'EARLY_LEAVE',
}

export interface Timetable {
  items: TimetableItem[]
}

export interface TimetableItem {
  dayOfWeek: number
  startTime: Time
  endTime: Time
}

export interface Time {
  hour: number
  minute: number
}
