import { atom } from 'recoil'

const loginState = atom({
  key: 'loginState',
  default: {
    isLoggedIn: null,
    organizationId: null,
    id: null,
    name: null,
  },
})

export default loginState
