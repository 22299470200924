import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import LogoutModal from './LogoutModal'

import LogoSidebar from 'assets/logos/logo-sidebar.svg'
import SidebarGridfillBlue from 'assets/images/sidebar-gridfill-blue.svg'
import SidebarGridfillGray from 'assets/images/sidebar-gridfill-gray.png'
// import SidebarClassroomBlue from 'assets/images/sidebar-classroom-blue.svg'
// import SidebarClassroomGray from 'assets/images/sidebar-classroom-gray.svg'
import SidebarElectricboardBlue from 'assets/images/sidebar-electricboard-blue.svg'
import SidebarElectricboardGray from 'assets/images/sidebar-electricboard-gray.svg'
import SidebarNoticeboardBlue from 'assets/images/sidebar-noticeboard-blue.svg'
import SidebarNoticeboardGray from 'assets/images/sidebar-noticeboard-gray.svg'
import SidebarContentBlue from 'assets/images/sidebar-content-blue.svg'
import SidebarContentGray from 'assets/images/sidebar-content-gray.svg'
import SidebarClassroomAdminBlue from 'assets/images/sidebar-classroomAdmin-blue.svg'
import SidebarClassroomAdminGray from 'assets/images/sidebar-classroomAdmin-gray.svg'
import SidebarLogout from 'assets/images/sidebar-logout.svg'
import CloseSidebar from 'assets/images/sidebar-arrow-left.svg'
import OpenSidebar from 'assets/images/sidebar-arrow-right.svg'
import { useRecoilState } from 'recoil'
import sidebarState from 'states/sidebarState.ts'

export default function SideBar() {
  const location = useLocation()
  const navigate = useNavigate()

  const [sidebar, setSidebar] = useRecoilState(sidebarState)
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)

  const navigationList = [
    {
      title: '홈',
      path: '/',
      svgBlue: SidebarGridfillBlue,
      svgGray: SidebarGridfillGray,
    },
    {
      title: '전자칠판 관리',
      path: '/electricboard',
      svgBlue: SidebarElectricboardBlue,
      svgGray: SidebarElectricboardGray,
    },
    {
      title: '디지털 교실 알림판 관리',
      path: '/noticeboard',
      svgBlue: SidebarNoticeboardBlue,
      svgGray: SidebarNoticeboardGray,
    },
    {
      title: '콘텐츠 관리',
      path: '/content',
      svgBlue: SidebarContentBlue,
      svgGray: SidebarContentGray,
    },
    {
      title: '수업관리',
      path: '/classroomAdmin',
      svgBlue: SidebarClassroomAdminBlue,
      svgGray: SidebarClassroomAdminGray,
    },
  ]

  return sidebar.isOpen ? (
    <div className=' shrink-0 w-[260px] h-screen bg-neutral-7'>
      <div className='flex flex-col h-full shrink-0'>
        <div className='flex gap-[4.45px] mt-[50px] ml-40 mb-40'>
          <img src={LogoSidebar} alt='' />
          <p className='text-white text-[27.85px] font-semibold leading-[27.85px] tracking-[-1.114px] select-none'>
            Edu LMS
          </p>
          <div
            className='fixed left-[245px] cursor-pointer border border-[#E1E2E4] flex justify-center items-center rounded-[99px] p-4 bg-neutral-17'
            onClick={() =>
              setSidebar({
                isOpen: false,
              })
            }
          >
            <img src={CloseSidebar} alt='' />
          </div>
        </div>

        {/* menu */}
        <div className='flex flex-col'>
          {navigationList.map((nav, index) => (
            <Link
              key={index}
              to={nav.path}
              className={`mx-16 pl-24 py-[13px] rounded-[39px] text-body1-semibold select-none cursor-pointer ${
                location.pathname === nav.path ||
                (index === 4 && location.pathname.startsWith('/classroomAdmin/classroomDetail')) ||
                (index === 4 && location.pathname.startsWith('/classroomAdmin/attendance'))
                  ? 'bg-neutral-17 text-white'
                  : 'bg-none text-text-light-info'
              }`}
              onClick={() => navigate(nav.path)}
            >
              <div>
                <div className='flex gap-12'>
                  <img
                    src={
                      location.pathname === nav.path ||
                      (index === 4 &&
                        location.pathname.startsWith('/classroomAdmin/classroomDetail')) ||
                      (index === 4 && location.pathname.startsWith('/classroomAdmin/attendance'))
                        ? nav.svgBlue
                        : nav.svgGray
                    }
                    alt=''
                  />
                  <p>{nav.title}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* 로그아웃 */}
        <div className='grow flex items-end mb-[30px]'>
          <div
            className='mx-16 pl-24 py-[13px] rounded-[39px] text-body1-semibold select-none cursor-pointer ${
                bg-none text-text-light-info'
          >
            <div className='flex gap-12' onClick={() => setLogoutModalOpen(true)}>
              <img src={SidebarLogout} alt='' />
              <p>로그아웃</p>
            </div>
            {logoutModalOpen && (
              <LogoutModal
                logoutModalOpen={logoutModalOpen}
                setLogoutModalOpen={setLogoutModalOpen}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className='fixed -left-[10px] mt-[50px] cursor-pointer border border-[#E1E2E4] flex justify-center items-center rounded-[99px] p-4 bg-neutral-17'
      onClick={() =>
        setSidebar({
          isOpen: true,
        })
      }
    >
      <img src={OpenSidebar} alt='' />
    </div>
  )
}
