import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { list, create, del } from 'apis/lecture.ts'
import ButtonAdd from 'assets/images/button-add.svg'
// import ButtonTrash from 'assets/images/button-trash.svg'
import ArrowLeft from 'assets/logos/content-arrow-left.svg'
import ArrowRight from 'assets/logos/content-arrow-right.svg'
import ClassroomAddModal from 'components/ClassroomAddModal'
import ClassroomDeleteModal from 'components/ClassroomDeleteModal'

/* const classroomData = [
  {
    dayOfTheWeek: '화',
    time: '13시~14시',
    className: '기업자원통합관리',
    numberOfStudent: '31',
    attendanceManagement: '출석관리',
    classroomLink: '/classroom',
  },
] */

export default function ClassroomAdmin() {
  const [addClassroom, setAddClassroom] = useState(false)
  const [classroomData, setClassroomData] = useState([])
  const [deleteClassroom, setDeleteClassroom] = useState(false)
  const [deleteClassroomName, setDeleteClassroomName] = useState('')
  const [deleteClassroomId, setDeleteClassroomId] = useState('')

  const [currentContentPage, setCurrentContentPage] = useState(1)
  const [maxContentPage] = useState(1)

  const prevContentPage = () => {
    setCurrentContentPage(currentContentPage === 1 ? 1 : currentContentPage - 1)
  }

  const nextContentPage = () => {
    setCurrentContentPage(
      currentContentPage === maxContentPage ? maxContentPage : currentContentPage + 1
    )
  }
  const getListApi = async () => {
    const listRes = await list()
    if (listRes.error) {
      console.log(listRes.message)
    } else {
      let tempList = []
      listRes.data.lectures.forEach((classroom) => {
        let tempItem = {}
        tempItem.id = classroom.id
        tempItem.dayOfTheWeek = classroom.timetable.items[0].dayOfWeek
        tempItem.time = classroom.timetable.items[0].startTime
        tempItem.className = classroom.name
        tempItem.numberOfStudent = classroom.studentCount
        tempItem.teacher = classroom.teacher
        tempItem.classroomLink = '/classroom/' + classroom.id
        tempItem.attendanceManagement = '출석관리'
        tempList.push(tempItem)
      })
      console.log(tempList)
      setClassroomData(tempList)
    }
  }

  const createApi = async (body) => {
    const createRes = await create(body)
    if (createRes.error) {
      alert(createRes.message)
    }
  }

  const delApi = async (body) => {
    const delRes = await del(body)
    if (delRes.error) {
      alert(delRes.message)
    }
  }
  useEffect(() => {
    getListApi()
  }, [])
  return (
    <>
      <div className='overflow-y-auto h-screen px-[60px] py-[60px] grow bg-background-light-alternative'>
        <div className='flex flex-col gap-32'>
          <div className='flex flex-col w-full'>
            {/* 수업관리 */}
            <div className='min-h-[70px] flex gap-[10px] px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
              <p className='text-black select-none grow text-title3-bold'>수업관리</p>
              {!deleteClassroom && (
                <div className='flex gap-[10px]'>
                  <div
                    className='flex gap-4 px-16 py-8 bg-white border border-blue-200 cursor-pointer select-none shrink-0 rounded-6'
                    onClick={() => setAddClassroom(true)}
                  >
                    <img src={ButtonAdd} alt='' />
                    <p className='text-body2-medium shrink-0 text-text-light'>추가하기</p>
                  </div>
                  {/* <div
                  className='flex gap-4 px-16 py-8 bg-white border border-blue-200 cursor-pointer select-none rounded-6'
                  onClick={() => setDeleteClassroom(true)}
                >
                  <img src={ButtonTrash} alt='' />
                  <p className='text-body2-medium text-text-light'>삭제하기</p>
                </div> */}
                </div>
              )}
              {deleteClassroom && (
                <div
                  className='flex px-16 py-8 cursor-pointer select-none bg-danger-base rounded-6'
                  onClick={() => setDeleteClassroom(false)}
                >
                  <p className='text-white text-body2-medium'>확인</p>
                </div>
              )}
            </div>

            {/* 수업목록 */}
            <div className='px-20 py-40 bg-white border border-border-light rounded-b-8'>
              <div className='grid items-center h-40 grid-cols-7 text-center bg-background-light-alternative text-body1-medium text-text-light-info'>
                <p>선생님</p>
                <p>요일</p>
                <p>시간</p>
                <p>과목명</p>
                <p>학생수</p>
                <p>학생관리 / 출석관리</p>
                <p>수업방 입장 / 삭제</p>
              </div>
              <div className='flex flex-col gap-4 divide-y'>
                {classroomData.map((classroom, index) => (
                  <div key={index} className='grid grid-cols-7 min-h-[60px] items-center'>
                    {/* 선생님 */}
                    <p className={`text-center  text-h2-medium text-text-light`}>
                      {classroom.teacher}
                    </p>
                    {/* 요일 */}
                    <p className={`text-center  text-h2-medium text-text-light`}>
                      {classroom.dayOfTheWeek}
                    </p>
                    {/* 시간 */}
                    <p className={`text-center  text-h2-medium text-text-light shrink-0 truncate`}>
                      {classroom.time}
                    </p>

                    {/* 과목명 */}
                    <p className={`text-center select-none text-h2-medium text-text-light`}>
                      {classroom.className}
                    </p>

                    {/* 학생수 */}
                    <p className={`text-center  text-h2-medium text-text-light`}>
                      {classroom.numberOfStudent}
                    </p>

                    {/* 출석관리 */}
                    <div className={`flex gap-[10px] justify-center text-center select-none `}>
                      <Link
                        to={`/classroomAdmin/classroomDetail/${classroom.id}`}
                        state={{
                          className:
                            classroom.className +
                            ' ' +
                            classroom.dayOfTheWeek +
                            ' ' +
                            classroom.time,
                        }}
                        className={`shrink-0 flex justify-center text-center select-none `}
                      >
                        <p
                          className={`px-12 py-8 border max-w-[85px] text-body2-medium ${
                            deleteClassroom
                              ? 'text-text-light-assistive'
                              : 'text-text-light-sub cursor-pointer '
                          } border-border-light rounded-6 bg-white`}
                        >
                          학생관리
                        </p>
                      </Link>

                      <Link
                        to={`/classroomAdmin/attendance/${classroom.id}`}
                        state={{
                          className:
                            classroom.className +
                            ' ' +
                            classroom.dayOfTheWeek +
                            ' ' +
                            classroom.time,
                        }}
                        className={`shrink-0 flex justify-center text-center select-none `}
                      >
                        <p
                          className={`px-12 py-8 border max-w-[85px] text-body2-medium ${
                            deleteClassroom
                              ? 'text-text-light-assistive'
                              : 'text-text-light-sub cursor-pointer '
                          } border-border-light rounded-6 bg-white`}
                        >
                          {classroom.attendanceManagement}
                        </p>
                      </Link>
                    </div>

                    {/* 수업방 입장/삭제 */}
                    <div className={`flex gap-[10px] justify-center text-center select-none `}>
                      <Link
                        to={classroom.classroomLink}
                        className={`px-12 shrink-0 py-8 max-w-[100px] text-body2-medium text-white rounded-6 ${
                          deleteClassroom
                            ? 'bg-background-light-strong'
                            : 'bg-primary cursor-pointer '
                        }`}
                      >
                        수업방 입장
                      </Link>
                      <div
                        className='flex px-12 py-8 cursor-pointer select-none shrink-0 bg-danger-base rounded-6'
                        onClick={() => {
                          setDeleteClassroomName(classroom.className)
                          setDeleteClassroomId(classroom.id)
                        }}
                      >
                        <p className='text-white text-body2-medium'>삭제</p>
                      </div>
                    </div>
                    {deleteClassroomName && (
                      <ClassroomDeleteModal
                        deleteClassroomId={deleteClassroomId}
                        deleteClassroomName={deleteClassroomName}
                        setDeleteClassroomName={setDeleteClassroomName}
                        delApi={delApi}
                        getListApi={getListApi}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* 페이지 넘버 표시 */}
          <div className='flex items-center justify-center gap-8 select-none'>
            <div
              className='flex py-6 pl-4 pr-8 bg-white cursor-pointer rounded-6 text-body1-medium text-text-light-sub'
              onClick={prevContentPage}
            >
              <button>
                <img src={ArrowLeft} alt='' />
              </button>
              <p>이전</p>
            </div>
            {Array.from({ length: maxContentPage }, (_, i) => i + 1).map((page) => (
              <div
                className={`${
                  currentContentPage === page
                    ? 'bg-primary-active text-white'
                    : 'bg-white text-text-light-sub'
                } w-40 h-40 py-6 flex items-center justify-center rounded-6 cursor-pointer`}
                onClick={() => setCurrentContentPage(page)}
              >
                <p>{page}</p>
              </div>
            ))}
            <div className='flex items-center justify-center'>
              <div
                className='flex py-6 pl-8 pr-4 bg-white cursor-pointer rounded-6 text-body1-medium text-text-light-sub'
                onClick={nextContentPage}
              >
                <p>다음</p>
                <button>
                  <img src={ArrowRight} alt='' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addClassroom && (
        <ClassroomAddModal
          addClassroom={addClassroom}
          setAddClassroom={setAddClassroom}
          createApi={createApi}
          getListApi={getListApi}
        />
      )}
    </>
  )
}
