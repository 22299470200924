import { atom } from 'recoil'

const sidebarState = atom({
  key: 'sidebarState',
  default: {
    isOpen: true,
  },
})

export default sidebarState
