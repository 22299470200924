import React, { useEffect, useState, useRef } from 'react'
import { components } from 'components/customMenu'
// import { useRecoilState } from 'recoil'
import { Tldraw } from 'tldraw'
import { useSync } from '@tldraw/sync'
// import sidebarState from 'states/sidebarState.ts'
import BrowseFileModal from 'components/BrowseFileModal'

import Time from 'assets/logos/electricboard-time.svg'
import File from 'assets/logos/electricboard-file.svg'
import Picture from 'assets/logos/electricboard-picture.svg'
import Record from 'assets/logos/electricboard-record.svg'
import Chat from 'assets/logos/electricboard-chat.svg'
import Mike from 'assets/logos/electricboard-mike.svg'
import Camera from 'assets/logos/electricboard-camera.svg'
import People from 'assets/logos/electricboard-people.svg'

import { minutesToHourAndMinutes2 } from 'apis/common.ts'
import { page as pageApi } from 'apis/content.ts'
import { TLDRAW_WORKER_URL } from 'constants/settings.ts'
import { multiplayerAssetStore } from 'components/multiplayerAssetStore.tsx'
import { getBookmarkPreview } from 'components/getBookmarkPreview.tsx'
import { useParams } from 'react-router-dom'

export default function Classroom() {
  const [browseFileModalOpen, setBrowseFileModalOpen] = useState(false)
  // const [sidebar] = useRecoilState(sidebarState)
  const [minutes, setMinutes] = useState(0)
  const { id } = useParams()

  // const [pdfWidth, setPdfWidth] = useState((window.innerWidth - (sidebar.isOpen ? 260 : 0)) / 2)

  // useEffect(() => {
  //   setPdfWidth((window.innerWidth - (sidebar.isOpen ? 260 : 0)) / 2)
  // }, [sidebar.isOpen])

  // const handleResize = () => {
  //   setPdfWidth((window.innerWidth - (sidebar.isOpen ? 260 : 0)) / 2)
  // }

  // useEffect(() => {
  //   setPdfWidth((window.innerWidth - (sidebar.isOpen ? 260 : 0)) / 2)
  // }, [sidebar.isOpen])

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize)
  //   return () => {
  //     // cleanup
  //     window.removeEventListener('resize', handleResize)
  //   }
  // })

  useEffect(() => {
    let interval = setInterval(() => {
      setMinutes(minutes + 1)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })

  const getDimensions = async (url) => {
    const img = new Image()
    img.src = url
    await img.decode()
    return img
  }

  const nowHeight = useRef(0)
  const editorRef = useRef(null)

  const addPage = async (contentId, totalPage) => {
    console.log(contentId, totalPage)
    const editor = editorRef.current
    for await (const page of [...Array(totalPage).keys()]) {
      // get image size from url
      const image = await getDimensions(pageApi(contentId, page + 1))
      const { width, height } = image

      editor.createAssets([
        {
          id: `asset:${contentId}-${page}`,
          typeName: 'asset',
          type: 'image',
          meta: {},
          props: {
            w: width,
            h: height,
            mimeType: 'image/png',
            src: image.src,
            name: 'page',
            isAnimated: false,
          },
        },
      ])
      editor.createShapes([
        {
          id: `shape:${contentId}-${page}`,
          type: 'image',
          x: window.innerWidth / 2 - width / 2,
          y: nowHeight.current,
          isLocked: true,
          props: {
            assetId: `asset:${contentId}-${page}`,
            w: width / 2,
            h: height / 2,
          },
        },
      ])
      nowHeight.current += height / 2 + 20
    }
  }

  const roomId = id
  const store = useSync({
    uri: TLDRAW_WORKER_URL + '/connect/' + roomId,
    assets: multiplayerAssetStore,
  })

  return (
    <div className='w-full h-screen'>
      {browseFileModalOpen && (
        <BrowseFileModal
          addPage={addPage}
          setBrowseFileModalOpen={setBrowseFileModalOpen}
        ></BrowseFileModal>
      )}
      <div className=' shrink-0 h-[94px] flex justify-center items-center'>
        <div className='flex items-center justify-center'>
          <div className='flex justify-center w-[120px] items-center overflow-hidden gap-8 px-20 py-6 rounded-[100px] bg-[#D4E1FF] mr-12 text-body2-long-medium text-primary select-none cursor-pointer'>
            <img src={Time} alt='' />
            <p>{minutesToHourAndMinutes2(minutes)}</p>
          </div>
          <div
            className='flex justify-center items-center gap-8 px-20 py-6 border border-border-light rounded-[100px] bg-white mr-6 text-body2-long-medium text-text-light select-none cursor-pointer'
            onClick={() => setBrowseFileModalOpen(true)}
          >
            <img src={File} alt='' />
            <p>파일 불러오기</p>
          </div>
          <div className='flex justify-center items-center gap-8 px-20 py-6 border border-border-light rounded-[100px] bg-white mr-6 text-body2-long-medium text-text-light select-none cursor-pointer'>
            <img src={Picture} alt='' />
            <p>이미지/동영상 삽입하기</p>
          </div>
          <div className='flex justify-center items-center gap-8 px-20 py-6 border border-[#FCD4DE] rounded-[100px] bg-[#FF5959] mr-6 text-body2-long-medium text-white select-none cursor-pointer'>
            <img src={Record} alt='' />
            <p>녹화하기</p>
          </div>
          {/* <div className='flex justify-center items-center px-20 py-6 border border-border-light rounded-[100px] bg-white mr-12'>
            <div className='flex justify-center items-center gap-4 mr-[11px]'>
              <img src={Back} alt='' />
              <img src={Return} alt='' />
            </div>
            <div className='border-[1.5px] h-[25px]'></div>
            <div className='flex justify-center items-center gap-8 mx-[11px]'>
              <img src={Arrow} alt='' />
              <img src={Edit} alt='' />
              <img src={Highliter} alt='' />
              <img src={Eraser} alt='' />
            </div>
            <div className='border-[1.5px] h-[25px]'></div>
            <div className='flex justify-center items-center gap-8 mx-[11px]'>
              <img src={Round1} alt='' />
              <img src={Round2} alt='' />
              <img src={Round3} alt='' />
              <img src={Round4} alt='' />
            </div>
            <div className='border-[1.5px] h-[25px]'></div>
            <div className='flex justify-center items-center gap-8 mx-[11px]'>
              <img src={Round5} alt='' />
              <img src={Round6} alt='' />
              <img src={Round7} alt='' />
            </div>
          </div> */}
          <div className='flex justify-between gap-8 drag-none'>
            <img src={Chat} alt='' className='cursor-pointer ' />
            <img src={Mike} alt='' className='cursor-pointer ' />
            <img src={Camera} alt='' className='cursor-pointer ' />
            <img src={People} alt='' className='cursor-pointer ' />
          </div>
        </div>
      </div>
      <div className='flex w-full overflow-y-auto h-[calc(100%-94px)]'>
        {/* <div className='flex items-center justify-center bg-black basis-1/2 shrink-0'>
          <Viewer pdfWidth={pdfWidth} />
        </div> */}
        <div className='z-0 w-full shrink-0'>
          <Tldraw
            store={store}
            onMount={(editor) => {
              editorRef.current = editor
              editor.registerExternalAssetHandler('url', getBookmarkPreview)
            }}
            components={components}
          ></Tldraw>
        </div>
      </div>
    </div>
  )
}
