import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { list, thumbnail, download, upload } from 'apis/content.ts'
import { useSetRecoilState } from 'recoil'
import loadingState from 'states/loadingState.ts'

import PDF from 'assets/logos/content-pdf.svg'
import Public from 'assets/logos/content-public.svg'
import Private from 'assets/logos/content-private.svg'
import ArrowLeft from 'assets/logos/content-arrow-left.svg'
import ArrowRight from 'assets/logos/content-arrow-right.svg'
import DownloadIcon from 'assets/logos/download.svg'

export default function Content() {
  const setLoading = useSetRecoilState(loadingState)
  const [contentState, setContentState] = useState('admin')
  const [isPrivate, setIsPrivate] = useState(false)
  const [fileName, setFileName] = useState('파일을 업로드해주세요')
  const [fileData, setFileData] = useState(null)

  const [currentContentPage, setCurrentContentPage] = useState(1)
  const [maxContentPage] = useState(5)
  const [contentList, setContentList] = useState([
    /* {
      title: '기업자원통합관리(ERP)분석 및 보고서 작성.pdf',
      extension: 'pdf',
      img: require('assets/images/content-sumnail.png'),
      type: 'public',
      division: '기업자원통합관리',
    }, */
  ])

  const prevContentPage = () => {
    setCurrentContentPage(currentContentPage === 1 ? 1 : currentContentPage - 1)
  }

  const nextContentPage = () => {
    setCurrentContentPage(
      currentContentPage === maxContentPage ? maxContentPage : currentContentPage + 1
    )
  }
  const onClickUpload = async () => {
    setLoading({ isLoading: true, message: '파일을 업로드하고 있습니다...' })
    const uploadRes = await upload(fileData, isPrivate)
    if (uploadRes.error) {
      console.log(uploadRes.message)
    } else {
      getList()
      setContentState('admin')
    }
    setLoading({ isLoading: false })
  }
  const onClickDownload = (content) => async () => {
    const downloadRes = download(content.id)
    const url = downloadRes
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = url
    link.setAttribute('download', content.title)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getList = async () => {
    const listRes = await list()
    if (listRes.error) {
      console.log(listRes.message)
    } else {
      console.log(listRes.data)
      setContentList([])
      listRes.data.contents.forEach((content) => {
        let tempItem = {}
        tempItem.id = content.id
        tempItem.title = content.name
        tempItem.extension = 'pdf'
        tempItem.type = content.isPrivate ? 'private' : 'public'
        tempItem.division = Math.round(content.fileSize / 1024)
        tempItem.img = thumbnail(content.id)
        setContentList((prev) => [...prev, tempItem])
      })
    }
  }
  useEffect(() => {
    getList()
  }, [])
  return (
    <div className='overflow-y-auto h-screen px-[60px] py-[60px] grow bg-background-light-alternative'>
      <div className='flex flex-col gap-32 '>
        {/* 콘텐츠 메뉴 바 */}
        <div className='flex'>
          <div
            className={`${
              contentState === 'admin'
                ? 'border-b-primary-active text-primary'
                : 'border-b-border-light text-text-light-info'
            } border-b-[3px] transition-all duration-200 text-h2-semibold select-none cursor-pointer`}
            onClick={() => setContentState('admin')}
          >
            <p className='mx-[100px] my-[15px]'>콘텐츠 관리</p>
          </div>
          <div
            className={`${
              contentState === 'upload'
                ? 'border-b-primary-active text-primary'
                : 'border-b-border-light text-text-light-info'
            } border-b-[3px] transition-all duration-200 text-h2-semibold select-none cursor-pointer`}
            onClick={() => setContentState('upload')}
          >
            <p className='mx-[100px] my-[15px]'>콘텐츠 업로드</p>
          </div>
        </div>

        {/* 콘텐츠 목록 */}
        {contentState === 'admin' ? (
          // 콘텐츠 관리
          <div className='flex flex-col w-full select-none'>
            <div className='min-h-[70px] flex px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
              <p className='text-title3-bold text-[#000000] select-none'>콘텐츠 목록</p>
            </div>
            <div className='px-20 py-40 bg-[#FFFFFF] border border-border-light text-text-light-info  rounded-b-8'>
              <div className='grid grid-cols-3 gap-x-32 gap-y-24'>
                {contentList.map((content, index) => (
                  <div className='bg-background-light-alternative px-20 py-20 rounded-8 border-[0.484px] border-border-light'>
                    <div className='flex flex-col gap-12'>
                      {/* 콘텐츠 제목 */}
                      <div className='flex justify-between gap-4'>
                        <div className='flex items-center gap-4 truncate'>
                          {content.extension === 'pdf' && <img src={PDF} alt='' />}
                          <p className='truncate text-body1-long-semibold text-text-light'>
                            {content.title}
                          </p>
                        </div>
                        <img
                          src={DownloadIcon}
                          alt='다운로드'
                          onClick={onClickDownload(content)}
                          className='cursor-pointer select-none w-[24px]'
                        />
                      </div>

                      {/* 콘텐츠 이미지 */}
                      <div className='h-[180px] overflow-hidden rounded-4 border border-border-light'>
                        <img src={content.img} alt='' className='w-full' />
                      </div>

                      {/* 콘텐츠 설명 */}
                      <div className='flex items-center justify-between gap-12'>
                        {content.type === 'public' && <img src={Public} alt='' />}
                        {content.type === 'private' && <img src={Private} alt=''></img>}
                        <div className='text-body1-long-medium text-text-light-sub'>
                          <p>{content.division} MB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          // 콘텐츠 업로드
          <div className='flex flex-col w-full '>
            <div className='min-h-[70px] flex px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
              <p className='text-title3-bold text-[#000000] select-none'>콘텐츠 업로드</p>
            </div>
            <div className='px-80 py-40 bg-[#FFFFFF] border border-border-light rounded-b-8'>
              <div className='flex flex-col justify-between'>
                <div className='flex flex-col gap-32'>
                  {/* 과목명, 공개여부 */}
                  <div className='flex gap-4'>
                    <div className='flex items-center select-none'>
                      <p className='text-body1-semibold text-text-light w-[104px]'>공개여부</p>
                    </div>
                    <div className='flex items-center gap-12 '>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setIsPrivate(false)}
                      >
                        <div
                          className={`${
                            !isPrivate
                              ? 'bg-information-surface text-information-base'
                              : 'bg-gray-200 text-gray-400'
                          } transition-all duration-200 rounded-4 px-[12px] py-[6px]  text-body2-medium`}
                        >
                          공개
                        </div>
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setIsPrivate(true)}
                      >
                        <div
                          className={`${
                            isPrivate
                              ? 'bg-danger-border text-danger-text'
                              : 'bg-gray-200 text-gray-400'
                          } transition-all duration-200 rounded-4 px-[12px] py-[6px] text-body2-medium`}
                        >
                          비공개
                        </div>
                      </motion.button>
                    </div>
                  </div>

                  {/* 파일 업로드 */}
                  <div className='flex gap-20'>
                    <div className='flex items-center gap-24'>
                      <label
                        htmlFor='file'
                        className='select-none min-w-80 text-body1-semibold text-text-light'
                      >
                        파일 업로드
                      </label>
                      <div className='min-w-[400px] bg-background-light-alternative border border-border-light rounded-[8px] px-16 py-16'>
                        <input
                          type='text'
                          placeholder={fileName}
                          className='select-none bg-background-light-alternative'
                          disabled
                        />
                      </div>
                    </div>
                    <label
                      htmlFor='file'
                      className='flex items-center px-32 py-16 my-6 text-white cursor-pointer select-none bg-information-base rounded-8'
                    >
                      <input
                        type='file'
                        id='file'
                        className='hidden'
                        onChange={(e) => {
                          if (e.currentTarget.files[0]) {
                            setFileName(e.currentTarget.files[0].name)
                            setFileData(e.currentTarget.files[0])
                          }
                        }}
                      />
                      <p>파일 첨부</p>
                    </label>
                  </div>
                </div>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onClickUpload}
                  className='flex justify-center items-center mt-[200px] select-none cursor-pointer '
                >
                  <p className='px-[80px] py-[16px] rounded-6 bg-primary text-white text-body1-semibold'>
                    업로드하기
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        )}

        {/* 콘텐츠 관리일 때 페이지 넘버 표시 */}
        {contentState === 'admin' && (
          <div className='flex items-center justify-center gap-8 select-none'>
            <div
              className='flex py-6 pl-4 pr-8 bg-white cursor-pointer rounded-6 text-body1-medium text-text-light-sub'
              onClick={prevContentPage}
            >
              <button>
                <img src={ArrowLeft} alt='' />
              </button>
              <p>이전</p>
            </div>
            {Array.from({ length: maxContentPage }, (_, i) => i + 1).map((page) => (
              <div
                className={`${
                  currentContentPage === page
                    ? 'bg-primary-active text-white'
                    : 'bg-white text-text-light-sub'
                } w-40 h-40 py-6 flex items-center justify-center rounded-6 cursor-pointer`}
                onClick={() => setCurrentContentPage(page)}
              >
                <p>{page}</p>
              </div>
            ))}
            <div className='flex items-center justify-center'>
              <div
                className='flex py-6 pl-8 pr-4 bg-white cursor-pointer rounded-6 text-body1-medium text-text-light-sub'
                onClick={nextContentPage}
              >
                <p>다음</p>
                <button>
                  <img src={ArrowRight} alt='' />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
