import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import DeleteIcon from 'assets/logos/delete.svg'

const ziggleVariants = {
  onZiggle: {
    rotate: [0, -0.4, 0, 0.4, 0],
  },
  offZiggle: {
    rotate: [0, 0, 0, 0, 0],
  },
}

export default function NoticeGrid() {
  const [isNoticeEditMode, setIsNoticeEditMode] = useState(false)
  const [isNoticeAddMode, setIsNoticeAddMode] = useState(false)
  const onClickEditMode = () => {
    setIsNoticeEditMode((current) => !current)
    setIsNoticeAddMode(false)
  }
  const onClickAddMode = () => {
    setIsNoticeAddMode((current) => !current)
  }
  const onClickDelete = (index) => () => {
    setNoticeList((current) => {
      return current.filter((_, i) => i !== index)
    })
  }
  const onClickAddItem = (index) => () => {
    setNoticeList((current) => {
      const newNoticeList = [...current]
      newNoticeList.push(newNoticeItemList[index])
      return newNoticeList
    })
    setIsNoticeAddMode(false)
  }

  const [noticeList, setNoticeList] = useState([
    {
      cols: 2,
      rows: 2,
      imgPath: require('assets/widgets/weather_2-2.png'),
    },
    {
      cols: 2,
      rows: 2,
      imgPath: require('assets/widgets/food_2-2.png'),
    },
    {
      cols: 4,
      rows: 2,
      imgPath: require('assets/widgets/news_4-2.png'),
    },
    {
      cols: 2,
      rows: 2,
      imgPath: require('assets/widgets/calender_2-2.png'),
    },
    {
      cols: 1,
      rows: 1,
      imgPath: require('assets/widgets/weather_1-1.png'),
    },
    {
      cols: 1,
      rows: 1,
      imgPath: require('assets/widgets/calender_1-1.png'),
    },
    {
      cols: 2,
      rows: 1,
      imgPath: require('assets/widgets/gallery_2-1.png'),
    },
  ])

  const newNoticeItemList = [
    {
      cols: 1,
      rows: 1,
      imgPath: require('assets/widgets/weather_1-1.png'),
    },
    {
      cols: 1,
      rows: 1,
      imgPath: require('assets/widgets/calender_1-1.png'),
    },
    {
      cols: 1,
      rows: 1,
      imgPath: require('assets/widgets/food_1-1.png'),
    },
    {
      cols: 1,
      rows: 1,
      imgPath: require('assets/widgets/gallery_1-1.png'),
    },
    {
      cols: 2,
      rows: 1,
      imgPath: require('assets/widgets/weather_2-1.png'),
    },
    {
      cols: 2,
      rows: 1,
      imgPath: require('assets/widgets/calender_2-1.png'),
    },
    {
      cols: 2,
      rows: 1,
      imgPath: require('assets/widgets/food_2-1.png'),
    },
    {
      cols: 2,
      rows: 1,
      imgPath: require('assets/widgets/gallery_2-1.png'),
    },
    {
      cols: 2,
      rows: 2,
      imgPath: require('assets/widgets/weather_2-2.png'),
    },
    {
      cols: 2,
      rows: 2,
      imgPath: require('assets/widgets/calender_2-2.png'),
    },
    {
      cols: 2,
      rows: 2,
      imgPath: require('assets/widgets/food_2-2.png'),
    },
    {
      cols: 2,
      rows: 2,
      imgPath: require('assets/widgets/gallery_2-2.png'),
    },
    {
      cols: 4,
      rows: 2,
      imgPath: require('assets/widgets/news_4-2.png'),
    },
  ]
  return (
    <div className='flex flex-col'>
      {/* box-header */}
      <div className='justify-between box-header'>
        <p className='box-header-title'>알림판 구성</p>
        <div className='flex items-center gap-12'>
          <AnimatePresence mode='wait'>
            {isNoticeEditMode ? (
              <>
                <motion.div></motion.div>
                <motion.button
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className='button-normal'
                  onClick={onClickAddMode}
                >
                  <p>{isNoticeAddMode ? '닫기' : '+ 추가'}</p>
                </motion.button>
                <motion.button
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className='button-normal'
                  onClick={onClickEditMode}
                >
                  <p>완료</p>
                </motion.button>
              </>
            ) : (
              <motion.button
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className='button-normal'
                onClick={onClickEditMode}
              >
                <p>수정하기</p>
              </motion.button>
            )}
          </AnimatePresence>
        </div>
      </div>
      {/* box-body */}
      <div className='relative overflow-hidden box-body bg-background-light-alternative'>
        {/* notice editor */}
        <AnimatePresence>
          {isNoticeAddMode && (
            <>
              {/* gray background */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className='absolute w-full h-full top-0 left-0 bg-[#1F1F1F80] z-40'
              ></motion.div>
              {/* notice editor body*/}
              <motion.div
                initial={{ y: '100%' }}
                animate={{ y: 100 }}
                exit={{ y: '100%' }}
                className='absolute bottom-0 shadow-inset h-full pb-[136px] left-0 z-50 w-full px-36 pt-36 overflow-y-auto rounded-t-16 bg-background-light-alternative no-scroll'
              >
                <div className='notice-grid !gap-24 h-fit'>
                  {newNoticeItemList.map((notice, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.04, boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)' }}
                      whileTap={{ scale: 0.96, boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)' }}
                      style={{
                        gridRowEnd: `span ${notice.rows}`,
                        gridColumnEnd: `span ${notice.cols}`,
                      }}
                      onClick={onClickAddItem(index)}
                      className='relative flex cursor-pointer rounded-20'
                    >
                      <div className='w-full h-full'>
                        <img src={notice.imgPath} alt='' className='w-full' />
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>

        {/* notice grid */}
        <div className='notice-grid min-h-[200px]'>
          <AnimatePresence>
            {noticeList.map((notice, index) => (
              <motion.div
                layout
                drag={true}
                dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                whileDrag={{
                  scale: 1.05,
                  zIndex: 10,
                  z: 10,
                  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
                }}
                dragElastic={0.5}
                key={index}
                style={{
                  gridRowEnd: `span ${notice.rows}`,
                  gridColumnEnd: `span ${notice.cols}`,
                }}
                className='relative cursor-pointer rounded-20'
              >
                <motion.div
                  animate={isNoticeEditMode && !isNoticeAddMode ? 'onZiggle' : 'offZiggle'}
                  variants={ziggleVariants}
                  transition={{
                    duration: 0.2,
                    repeat: Infinity,
                  }}
                  className='w-full h-full'
                >
                  <AnimatePresence>
                    {isNoticeEditMode && !isNoticeAddMode && (
                      <motion.img
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        src={DeleteIcon}
                        alt='삭제'
                        onClick={onClickDelete(index)}
                        className='absolute top-[-10px] right-[-10px] z-30'
                      />
                    )}
                  </AnimatePresence>
                  <img src={notice.imgPath} alt='' className='w-full' />
                </motion.div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}
