import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { studentAttendanceList, studentAttendanceSave } from 'apis/lecture.ts'

import Person from 'assets/images/Person.svg'
// import ClassroomDetailPencil from 'assets/images/ClassroomDeatail-pencil.svg'
// import ClassroomDetailUserMultiple from 'assets/images/ClassroomDetail-user_multiple.svg'
// import ClassroomDetailUserFollow from 'assets/images/ClassroomDetail-user_follow.svg'
// import ClassroomDetailTrashcan from 'assets/images/ClassroomDetail-trashcan.svg'
// import ClassroomDetailModify from 'assets/images/ClassroomDetail-modify.svg'
import SidebarClassroomAdminBlue from 'assets/images/sidebar-classroomAdmin-blue.svg'
import ClassroomDetailPencil from 'assets/images/ClassroomDeatail-pencil.svg'
import moment from 'moment'
import { ATTENDANCE_STATUS } from 'apis/enum.ts'

export default function ClassroomDetail() {
  const location = useLocation()
  const className = location.state.className
  const classId = useParams().classId
  const [allAttendanceCheck, setAllAttendanceCheck] = useState(null)

  const [classStudentList, setClassStudentList] = useState([])

  const getStudentListApi = async () => {
    const studentListRes = await studentAttendanceList({
      lectureId: classId,
      date: moment().format('YYYY-MM-DD'),
    })
    if (studentListRes.error) {
      alert('학생 목록을 불러오는데 실패했습니다.')
      return
    }
    let tempList = []
    studentListRes.data.students.forEach((student) => {
      let tempItem = {
        isChecked: false,
        id: student.id,
        name: student.name,
        grade: student.grade + '학년',
        class: student.classNo + '반',
        phoneNum: student.phoneNumber,
        attendance: student.attendance,
        photo: Person,
      }
      tempList.push(tempItem)
    })
    setClassStudentList(tempList)
  }

  const checkAllStudents = async () => {
    for await (let classStudent of classStudentList) {
      classStudent.isChecked = true
    }
    let temp = [...classStudentList]
    setClassStudentList(temp)
  }

  const checkChange = (index, attendance) => {
    let temp = [...classStudentList]
    temp[index].attendance = attendance
    setClassStudentList(temp)
  }

  const checkAll = async () => {
    if (allAttendanceCheck === null) {
      return
    }

    for await (let classStudent of classStudentList) {
      if (classStudent.isChecked) {
        classStudent.attendance = allAttendanceCheck
      }
    }
    let temp = [...classStudentList]
    setClassStudentList(temp)
    setAllAttendanceCheck(null)
  }

  const save = async () => {
    let temp = []
    for await (let classStudent of classStudentList) {
      temp.push({
        id: classStudent.id,
        attendance: classStudent.attendance,
      })
    }
    console.log(temp)
    await studentAttendanceSave({
      lectureId: classId,
      date: moment().format('YYYY-MM-DD'),
      students: temp,
    })
    alert('출석 상태가 저장되었습니다.')
  }

  useEffect(() => {
    getStudentListApi()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='overflow-y-auto h-screen px-[60px] py-[60px] grow bg-background-light-alternative'>
      <div className='flex flex-col gap-32'>
        <div className='flex flex-col w-full'>
          {/* 출석관리 */}
          <div className='min-h-[70px] flex gap-[10px] px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
            <div className='flex grow'>
              <p className='text-black select-none text-title3-bold'>출석관리</p>
            </div>
            <div className='flex gap-[20px]'>
              <div className='flex items-center gap-12'>
                <div className='flex items-center gap-8'>
                  <input
                    type='checkbox'
                    id='selectAllAttendance'
                    checked={allAttendanceCheck === ATTENDANCE_STATUS.ATTEND}
                    className='w-[16.667px] h-[16.667px]'
                    onClick={() => setAllAttendanceCheck(ATTENDANCE_STATUS.ATTEND)}
                  />
                  <p className='text-body1-regular text-text-light'>출석</p>
                </div>
                <div className='flex items-center gap-8'>
                  <input
                    type='checkbox'
                    id='selectAllAbsence'
                    checked={allAttendanceCheck === ATTENDANCE_STATUS.ABSENT}
                    className='w-[16.667px] h-[16.667px]'
                    onClick={() => setAllAttendanceCheck(ATTENDANCE_STATUS.ABSENT)}
                  />
                  <p className='text-body1-regular text-text-light'>결석</p>
                </div>
                <div className='flex items-center gap-8'>
                  <input
                    type='checkbox'
                    id='selectAllTardy'
                    checked={allAttendanceCheck === ATTENDANCE_STATUS.LATE}
                    className='w-[16.667px] h-[16.667px]'
                    onClick={() => setAllAttendanceCheck(ATTENDANCE_STATUS.LATE)}
                  />
                  <p className='text-body1-regular text-text-light'>지각</p>
                </div>
                <div className='flex items-center gap-8'>
                  <input
                    type='checkbox'
                    id='selectAllLeave'
                    className='w-[16.667px] h-[16.667px]'
                    checked={allAttendanceCheck === ATTENDANCE_STATUS.EARLY_LEAVE}
                    onClick={() => setAllAttendanceCheck(ATTENDANCE_STATUS.EARLY_LEAVE)}
                  />
                  <p className='text-body1-regular text-text-light'>기타</p>
                </div>
              </div>
              <div className='flex gap-4 px-16 py-8 bg-white border border-blue-200 cursor-pointer select-none rounded-6'>
                <p
                  onClick={() => checkAll()}
                  className='cursor-pointer text-body2-medium text-text-light'
                >
                  일괄 상태 처리
                </p>
              </div>
            </div>
          </div>

          {/* 수업 목록 */}
          <div className='px-20 py-40 pt-[24px] bg-white border border-border-light rounded-b-8'>
            <div className='flex gap-8 mb-24'>
              <img src={SidebarClassroomAdminBlue} alt='' className='w-28 h-28' />
              <p className='text-h1-medium text-text-light-strong'>{className}</p>
              <img src={ClassroomDetailPencil} alt='' className='mt-[3px] cursor-pointer' />
            </div>
            <div className='flex items-center h-40 bg-background-light-alternative text-body1-medium text-text-light-info'>
              <p
                onClick={checkAllStudents}
                className=' underline text-center basis-[12.5%] cursor-pointer'
              >
                전체 선택
              </p>
              <p className='text-center basis-[12.5%]'>사진</p>
              <p className='text-center basis-[15%]'>이름</p>
              <p className='text-center basis-[10%]'>학년</p>
              <p className='text-center basis-[10%]'>반</p>
              <p className='text-center basis-[35%]'>출석 상태</p>
            </div>
            <div className='flex flex-col gap-4 divide-y'>
              {classStudentList.map((student, index) => (
                <div key={index} className='flex min-h-[60px] items-center'>
                  {/* 선택 */}
                  <div className='basis-[12.5%] flex justify-center '>
                    <input
                      type='checkbox'
                      className='w-[16.667px] h-[16.667px]'
                      onChange={(e) => {
                        let temp = [...classStudentList]
                        temp[index].isChecked = e.nativeEvent.target.checked
                        setClassStudentList(temp)
                      }}
                      checked={student.isChecked}
                    />
                  </div>
                  {/* 사진 */}
                  <div className='basis-[12.5%] flex justify-center'>
                    <img src={student.photo} alt='' />
                  </div>
                  {/* 이름 */}
                  <p className='basis-[15%] text-center text-body2-medium text-text-light'>
                    {student.name}
                  </p>
                  {/* 이름 */}
                  <p className='basis-[10%] text-center text-body2-medium text-text-light'>
                    {student.grade}
                  </p>
                  {/* 이름 */}
                  <p className='basis-[10%] text-center text-body2-medium text-text-light'>
                    {student.class}
                  </p>
                  {/* 출석 상태 */}
                  <div className='flex basis-[35%] text-center text-body2-medium text-text-light'>
                    <div className='flex items-center justify-center gap-8 basis-1/4'>
                      <input
                        type='checkbox'
                        name='attendance'
                        onChange={(e) => {
                          if (e.nativeEvent.target.checked) {
                            checkChange(index, ATTENDANCE_STATUS.ATTEND)
                          }
                        }}
                        checked={student.attendance === ATTENDANCE_STATUS.ATTEND}
                        className='w-[16.667px] h-[16.667px]'
                      />
                      <p className='text-body1-regular text-text-light'>출석</p>
                    </div>
                    <div className='flex items-center justify-center gap-8 basis-1/4'>
                      <input
                        type='checkbox'
                        name='absence'
                        onChange={(e) => {
                          if (e.nativeEvent.target.checked) {
                            checkChange(index, ATTENDANCE_STATUS.ABSENT)
                          }
                        }}
                        className='w-[16.667px] h-[16.667px]'
                        checked={student.attendance === ATTENDANCE_STATUS.ABSENT}
                      />
                      <p className='text-body1-regular text-text-light'>결석</p>
                    </div>
                    <div className='flex items-center justify-center gap-8 basis-1/4'>
                      <input
                        type='checkbox'
                        name='tardy'
                        onChange={(e) => {
                          if (e.nativeEvent.target.checked) {
                            checkChange(index, ATTENDANCE_STATUS.LATE)
                          }
                        }}
                        className='w-[16.667px] h-[16.667px]'
                        checked={student.attendance === ATTENDANCE_STATUS.LATE}
                      />
                      <p className='text-body1-regular text-text-light'>지각</p>
                    </div>
                    <div className='flex items-center justify-center gap-8 basis-1/4'>
                      <input
                        type='checkbox'
                        name='leave'
                        onChange={(e) => {
                          if (e.nativeEvent.target.checked) {
                            checkChange(index, ATTENDANCE_STATUS.EARLY_LEAVE)
                          }
                        }}
                        className='w-[16.667px] h-[16.667px]'
                        checked={student.attendance === ATTENDANCE_STATUS.EARLY_LEAVE}
                      />
                      <p className='text-body1-regular text-text-light'>조퇴/외출</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* 확인 */}
        <div className='flex items-center justify-center w-full '>
          <p
            onClick={() => save()}
            className='cursor-pointer w-[308px] h-[45px] pt-[11px] pb-[10px] bg-primary rounded-6 text-center text-body1-semibold text-white'
          >
            확인
          </p>
        </div>
      </div>
    </div>
  )
}
