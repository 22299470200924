import React, { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import chargingAnimation from 'assets/lotties/Animation_charging'
import { motion } from 'framer-motion'
import LineChart from 'components/LineChart'
import electricDurationState from 'states/electricDurationState.ts'
import { useRecoilState, useSetRecoilState } from 'recoil'
import * as deviceAPI from 'apis/device.ts'
import loadingState from 'states/loadingState.ts'
import OffSvg from 'assets/images/off.svg'
import { minutesToHourAndMinutes } from 'apis/common.ts'

export default function Electricboard() {
  const setLoading = useSetRecoilState(loadingState)

  const [device, setDevice] = useState(null)
  const [graph, setGraph] = useState(null)
  const [todayUseMinutes, setTodayUseMinutes] = useState(0)

  const [electricDuration, setElectricDuration] = useRecoilState(electricDurationState)

  const [reRender, setReRender] = useState(0)

  const handleResize = () => {
    // console.log(reRender)
    setReRender((prev) => prev + 1)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize)
    }
  })

  async function getDeviceDetail() {
    let detailRes = await deviceAPI.detail('board1')
    if (!detailRes.error) {
      setDevice(detailRes.data.device)
      setGraph(detailRes.data.graph)
      setTodayUseMinutes(detailRes.data.todayUseMinutes)
    }
  }

  useEffect(() => {
    getDeviceDetail()
  }, [])

  const off = async () => {
    setLoading({ isLoading: true, message: '기기를 종료하고 있습니다...' })
    await deviceAPI.off('board1')
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 2000)
    })
    await getDeviceDetail()
    setLoading({ isLoading: false })
  }

  return (
    <div className='overflow-y-auto h-screen px-[60px] py-[60px] grow bg-background-light-alternative'>
      <div className='flex flex-col gap-20'>
        {/* 우리반 전자칠판 */}
        {device && (
          <div className='flex flex-col w-full'>
            <div className='min-h-[70px] flex px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
              <p className='text-title3-bold text-[#000000] select-none'>{device.name}</p>
              <div className='flex items-center justify-end grow'>
                {device.status === 1 && (
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className='bg-danger-base rounded-6 pt-[11px] pb-[10px] pl-[47px] pr-[48px] text-body1-semibold text-white select-none cursor-pointer'
                    onClick={() => {
                      off()
                    }}
                  >
                    <p>원격종료</p>
                  </motion.div>
                )}
                {device.status === 0 && (
                  <div className='bg-neutral-300 rounded-6 pt-[11px] pb-[10px] pl-[47px] pr-[48px] text-body1-semibold text-white select-none'>
                    <p>종료됨</p>
                  </div>
                )}
              </div>
            </div>
            <div className='px-20 py-40 bg-[#FFFFFF] border border-border-light rounded-b-8 flex flex-col gap-24'>
              <div className='flex flex-col items-center justify-center gap-4 select-none'>
                <p className='text-body1-semibold text-primary'>Today</p>
                <p className='text-title3-medium text-text-light-sub'>2024.07.05 (금)</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                {device.status === 1 && (
                  <Lottie
                    animationData={chargingAnimation}
                    style={{ width: '200px', height: '100px' }}
                    speed={1}
                  ></Lottie>
                )}
                {device.status === 0 && <img src={OffSvg} alt='' className='scale-110' />}
                <div className='select-none text-title2-bold text-text-light'>
                  <p>
                    {minutesToHourAndMinutes(todayUseMinutes)}{' '}
                    {device.status === 1 ? '사용 중' : '사용함'}
                  </p>{' '}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* 오늘/이번주 사용시간 */}
        {graph && (
          <div className='flex gap-20'>
            <div className='flex flex-col w-full basis-1/2'>
              <div className='min-h-[70px] flex px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
                <p className='text-title3-bold text-[#000000] select-none'>
                  오늘의 사용시간 | 전력량
                </p>
              </div>
              <div className='px-20 py-40 bg-[#FFFFFF] border border-border-light rounded-b-8'>
                <LineChart
                  key={reRender}
                  graphData={{
                    labels: graph.today.labels,
                    datasets: [
                      {
                        label: '전력량',
                        data: graph.today.energy,
                        backgroundColor: 'rgba(255, 99, 99, 1)',
                        borderColor: 'rgba(255, 99, 99, 1)',
                        borderWidth: 3,
                        pointRadius: 4, // 포인트 크기
                        pointBorderWidth: 2,
                      },
                      {
                        label: '사용시간',
                        data: graph.today.minutes,
                        backgroundColor: 'rgba(183, 210, 250, 1)',
                        borderColor: 'rgba(204, 224, 255, 1)',
                        borderWidth: 3,
                        pointRadius: 4, // 포인트 크기
                        pointBorderWidth: 2,
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col w-full basis-1/2'>
              <div className='min-h-[70px] flex px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
                <p className='text-title3-bold text-[#000000] select-none'>
                  이번주 사용시간 | 전력량
                </p>
                <div className='flex items-center justify-end gap-12 grow'>
                  {console.log(electricDuration.duration)}
                  <div
                    className={`select-none cursor-pointer text-h1-semibold ${
                      electricDuration.duration === 'week' ? 'text-primary' : 'text-text-light-info'
                    }`}
                    onClick={() => setElectricDuration({ duration: 'week' })}
                  >
                    <p>이번주</p>
                  </div>
                  <div className='h-[22px] border border-text-light-info'></div>
                  <div
                    className={`select-none cursor-pointer text-h1-semibold ${
                      electricDuration.duration === 'month'
                        ? 'text-primary'
                        : 'text-text-light-info'
                    }`}
                    onClick={() => setElectricDuration({ duration: 'month' })}
                  >
                    <p>이번달</p>
                  </div>
                </div>
              </div>
              <div className='px-20 py-40 bg-[#FFFFFF] border border-border-light rounded-b-8'>
                <LineChart
                  key={reRender}
                  graphData={
                    electricDuration.duration === 'week'
                      ? {
                          labels: graph.week.labels,
                          datasets: [
                            {
                              label: '전력량',
                              data: graph.week.energy,
                              backgroundColor: 'rgba(255, 99, 99, 1)',
                              borderColor: 'rgba(255, 99, 99, 1)',
                              borderWidth: 3,
                              pointRadius: 4, // 포인트 크기
                              pointBorderWidth: 2,
                            },
                            {
                              label: '사용시간',
                              data: graph.week.minutes,
                              backgroundColor: 'rgba(183, 210, 250, 1)',
                              borderColor: 'rgba(204, 224, 255, 1)',
                              borderWidth: 3,
                              pointRadius: 4, // 포인트 크기
                              pointBorderWidth: 2,
                            },
                          ],
                        }
                      : {
                          labels: graph.month.labels,
                          datasets: [
                            {
                              label: '전력량',
                              data: graph.month.energy,
                              backgroundColor: 'rgba(255, 99, 99, 1)',
                              borderColor: 'rgba(255, 99, 99, 1)',
                              borderWidth: 3,
                              pointRadius: 4, // 포인트 크기
                              pointBorderWidth: 2,
                            },
                            {
                              label: '사용시간',
                              data: graph.month.minutes,
                              backgroundColor: 'rgba(183, 210, 250, 1)',
                              borderColor: 'rgba(204, 224, 255, 1)',
                              borderWidth: 3,
                              pointRadius: 4, // 포인트 크기
                              pointBorderWidth: 2,
                            },
                          ],
                        }
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
