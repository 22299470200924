import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  studentList,
  studentCreate,
  studentCreateMany,
  studentEdit,
  studentDelete,
  studentDeleteMany,
} from 'apis/lecture.ts'

import Person from 'assets/images/Person.svg'
import ClassroomDetailPencil from 'assets/images/ClassroomDeatail-pencil.svg'
import ClassroomDetailUserMultiple from 'assets/images/ClassroomDetail-user_multiple.svg'
import ClassroomDetailUserFollow from 'assets/images/ClassroomDetail-user_follow.svg'
import ClassroomDetailTrashcan from 'assets/images/ClassroomDetail-trashcan.svg'
import ClassroomDetailModify from 'assets/images/ClassroomDetail-modify.svg'
import ArrowLeft from 'assets/logos/content-arrow-left.svg'
import ArrowRight from 'assets/logos/content-arrow-right.svg'
import ClassroomModifyModal from './ClassroomModifyModal'
import BulkRegistrationModal from './BulkRegistrationModal'
import IndividualRegistrationModal from './IndividualRegistrationModal'
import ClassroomDeleteStudentModal from './ClassroomDeleteStudentModal'
import SidebarClassroomAdminBlue from 'assets/images/sidebar-classroomAdmin-blue.svg'
import ClassroomDeleteManyStudentModal from './ClassroomDeleteManyStudentModal'

/* const classStudentList = [
  {
    id: '1',
    photo: Person,
    name: '최지우',
    grade: '1학년',
    classNum: '5반',
    phoneNum: '010-1234-5678',
  },
] */

export default function ClassroomDetail() {
  const location = useLocation()
  const className = location.state.className
  const classId = useParams().classId

  const [classStudentList, setClassStudentList] = useState([])
  const [studentBulkRegistration, setStudentBulkRegistration] = useState(false) // 학생일괄등록
  const [studentIndividualRegistration, setStudentIndividualRegistration] = useState(false) // 학생개별등록

  const [modifyStudent, setModifyStudent] = useState(false)
  const [modifyStudentName, setModifyStudentName] = useState('')
  const [modifyStudentGrade, setModifyStudentGrade] = useState('')
  const [modifyStudentClassNum, setModifyStudentClassNum] = useState('')
  const [modifyStudentPhoneNum, setModifyStudentPhoneNum] = useState('')

  const [deleteStudent, setDeleteStudent] = useState(false)
  const [deleteStudentId, setDeleteStudentId] = useState('')
  const [deleteStudentName, setDeleteStudentName] = useState('')

  const [deleteManyStudent, setDeleteManyStudent] = useState(false)
  const [studentNum, setStudentNum] = useState('')

  const clickModifyStudent = (name, grade, classNum, phoneNum) => {
    setModifyStudent(true)
    setModifyStudentName(name)
    setModifyStudentGrade(grade)
    setModifyStudentClassNum(classNum)
    setModifyStudentPhoneNum(phoneNum)
  }
  const clickDeleteStudent = (studentId, studentName) => {
    setDeleteStudent(true)
    setDeleteStudentId(studentId)
    setDeleteStudentName(studentName)
  }
  const clickDeleteManyStudents = () => {
    const checks = document.querySelectorAll('.studentCheckbox')
    let len = 0
    checks.forEach((check) => {
      if (check.checked) {
        len++
      }
    })
    setStudentNum(len)
    if (len === 0) {
      alert('삭제할 학생을 선택해주세요.')
      return
    }
    setDeleteManyStudent(true)
  }

  const [currentContentPage, setCurrentContentPage] = useState(1)
  const [maxContentPage] = useState(1)

  const selectAll = () => {
    const checkboxes = document.querySelectorAll('.studentCheckbox')
    let isFullChecked = false
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        isFullChecked = true
      }
    })
    checkboxes.forEach((checkbox) => (checkbox.checked = !isFullChecked))
  }
  const prevContentPage = () => {
    setCurrentContentPage(currentContentPage === 1 ? 1 : currentContentPage - 1)
  }

  const nextContentPage = () => {
    setCurrentContentPage(
      currentContentPage === maxContentPage ? maxContentPage : currentContentPage + 1
    )
  }
  const getStudentListApi = async () => {
    const studentListRes = await studentList({ lectureId: classId })
    if (studentListRes.error) {
      alert('학생 목록을 불러오는데 실패했습니다.')
      return
    }
    let tempList = []
    studentListRes.data.students.forEach((student) => {
      let tempItem = {
        id: student.id,
        name: student.name,
        grade: student.grade,
        classNum: student.classNo,
        phoneNum: student.phoneNumber,
        photo: Person,
      }
      tempList.push(tempItem)
    })
    setClassStudentList(tempList)
  }

  const studentCreateApi = async (body) => {
    const studentCreateRes = await studentCreate({ lectureId: classId, ...body })
    if (studentCreateRes.error) {
      alert('학생 추가에 실패했습니다.')
      return
    }
    await getStudentListApi()
  }

  const studentCreateManyApi = async (studentsArr) => {
    const studentCreateManyRes = await studentCreateMany({
      lectureId: classId,
      students: studentsArr,
    })
    if (studentCreateManyRes.error) {
      alert('학생 일괄 추가에 실패했습니다.')
      return
    }
    await getStudentListApi()
  }

  const studentEditApi = async (body) => {
    const studentEditRes = await studentEdit({ lectureId: classId, ...body })
    if (studentEditRes.error) {
      alert('학생 수정에 실패했습니다.')
      return
    }
    await getStudentListApi()
  }

  const studentDeleteApi = async (studentId) => {
    const studentDeleteRes = await studentDelete({ lectureId: classId, studentId: studentId })
    if (studentDeleteRes.error) {
      alert('학생 삭제에 실패했습니다.')
      return
    }
    await getStudentListApi()
  }

  const studentDeleteManyApi = async () => {
    const studentIds = []
    const checkboxes = document.querySelectorAll('.studentCheckbox')
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        studentIds.push(checkbox.value)
      }
    })

    const studentDeleteManyRes = await studentDeleteMany({
      lectureId: classId,
      studentIds: studentIds,
    })
    if (studentDeleteManyRes.error) {
      alert('학생 삭제에 실패했습니다.')
      return
    }
    await getStudentListApi()
  }

  useEffect(() => {
    getStudentListApi()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='overflow-y-auto h-screen px-[60px] py-[60px] grow bg-background-light-alternative'>
        <div className='flex flex-col gap-32'>
          <div className='flex flex-col w-full'>
            {/* 수업관리_상세 */}
            <div className='min-h-[70px] flex gap-[10px] px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
              <div className='grow flex gap-[10px]'>
                <p className='text-black select-none text-title3-bold'>학생관리</p>
              </div>
              <div className='flex gap-[10px]'>
                <div
                  className='flex gap-4 px-16 py-8 bg-white border border-blue-200 cursor-pointer select-none rounded-6'
                  onClick={() => setStudentBulkRegistration(true)}
                >
                  <img src={ClassroomDetailUserMultiple} alt='' />
                  <p className='text-body1-medium text-text-light'>학생 일괄 등록</p>
                </div>
                <div
                  className='flex gap-4 px-16 py-8 bg-white border border-blue-200 cursor-pointer select-none rounded-6'
                  onClick={() => setStudentIndividualRegistration(true)}
                >
                  <img src={ClassroomDetailUserFollow} alt='' />
                  <p className='text-body1-medium text-text-light'>학생 개별 등록</p>
                </div>
                <div
                  onClick={() => clickDeleteManyStudents()}
                  className='flex gap-4 px-16 py-8 bg-white border border-blue-200 cursor-pointer select-none rounded-6'
                >
                  <img src={ClassroomDetailTrashcan} alt='' />
                  <p className='text-body1-medium text-text-light'>일괄 삭제</p>
                </div>
              </div>
            </div>

            {/* 수업 목록 */}
            <div className='px-20 pb-40 pt-[24px] bg-white border border-border-light rounded-b-8'>
              <div className='flex gap-8 mb-24'>
                <img src={SidebarClassroomAdminBlue} alt='' className='w-28 h-28' />
                <p className='text-h1-medium text-text-light-strong'>{className}</p>
                <img src={ClassroomDetailPencil} alt='' className='mt-[3px] cursor-pointer' />
              </div>

              <div className='flex items-center h-40 select-none bg-background-light-alternative text-body1-medium text-text-light-info'>
                <p onClick={selectAll} className='underline text-center basis-[10%] cursor-pointer'>
                  전체 선택
                </p>
                <p className='text-center basis-[10%]'>사진</p>
                <p className='text-center basis-[20%]'>이름</p>
                <p className='text-center basis-[10%]'>학년</p>
                <p className='text-center basis-[10%]'>반</p>
                <p className='text-center basis-[20%]'>연락처</p>
                <p className='text-center basis-[10%]'>수정</p>
                <p className='text-center basis-[10%]'>삭제</p>
              </div>
              <div className='flex flex-col gap-4 divide-y'>
                {classStudentList.map((student, index) => (
                  <div key={index} className='flex min-h-[60px] items-center'>
                    {/* 선택 */}
                    <div className='basis-[10%] flex justify-center '>
                      <input
                        type='checkbox'
                        name='student'
                        value={student.id}
                        className='studentCheckbox checkbox w-[16.667px] h-[16.667px]'
                      />
                    </div>
                    {/* 사진 */}
                    <div className='basis-[10%] flex justify-center'>
                      <img src={student.photo} alt='' />
                    </div>
                    {/* 이름 */}
                    <p className='basis-[20%] text-center text-body1-medium text-text-light'>
                      {student.name}
                    </p>
                    {/* 학년 */}
                    <p className='basis-[10%] text-center text-body1-medium text-text-light'>
                      {student.grade}학년
                    </p>
                    {/* 반 */}
                    <p className='basis-[10%] text-center text-body1-medium text-text-light'>
                      {student.classNum}반
                    </p>
                    {/* 연락처 */}
                    <p className='basis-[20%] text-center text-body1-medium text-text-light'>
                      {student.phoneNum}
                    </p>
                    {/* 수정 */}
                    <div className={'basis-[10%] flex justify-center text-center select-none '}>
                      <div
                        className='flex gap-4 px-16 py-8 border max-w-[110px] text-body1-medium text-text-light-sub cursor-pointer border-border-light rounded-6 bg-white'
                        onClick={() =>
                          clickModifyStudent(
                            student.name,
                            student.grade,
                            student.classNum,
                            student.phoneNum
                          )
                        }
                      >
                        <img src={ClassroomDetailModify} alt='' />
                        <p>수정하기</p>
                      </div>
                    </div>
                    {/* 삭제 */}
                    <div className={'basis-[10%] flex justify-center text-center select-none '}>
                      <div
                        onClick={() => clickDeleteStudent(student.id, student.name)}
                        className='flex gap-4 px-16 py-8 border max-w-[110px] text-body1-medium text-text-light-sub cursor-pointer border-border-light rounded-6 bg-white'
                      >
                        <img src={ClassroomDetailTrashcan} alt='' />
                        <p>삭제하기</p>
                      </div>
                    </div>
                    {modifyStudent && (
                      <ClassroomModifyModal
                        studentId={student.id}
                        name={modifyStudentName}
                        grade={modifyStudentGrade}
                        classNum={modifyStudentClassNum}
                        phoneNum={modifyStudentPhoneNum}
                        setModifyStudent={setModifyStudent}
                        studentEditApi={studentEditApi}
                      />
                    )}
                    {deleteStudent && (
                      <ClassroomDeleteStudentModal
                        studentId={deleteStudentId}
                        studentName={deleteStudentName}
                        setDeleteStudent={setDeleteStudent}
                        studentDeleteApi={studentDeleteApi}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* 페이지 넘버 표시 */}
          <div className='flex items-center justify-center gap-8 select-none'>
            <div
              className='flex py-6 pl-4 pr-8 bg-white cursor-pointer rounded-6 text-body1-medium text-text-light-sub'
              onClick={prevContentPage}
            >
              <button>
                <img src={ArrowLeft} alt='' />
              </button>
              <p>이전</p>
            </div>
            {Array.from({ length: maxContentPage }, (_, i) => i + 1).map((page) => (
              <div
                className={`${
                  currentContentPage === page
                    ? 'bg-primary-active text-white'
                    : 'bg-white text-text-light-sub'
                } w-40 h-40 py-6 flex items-center justify-center rounded-6 cursor-pointer`}
                onClick={() => setCurrentContentPage(page)}
              >
                <p>{page}</p>
              </div>
            ))}
            <div className='flex items-center justify-center'>
              <div
                className='flex py-6 pl-8 pr-4 bg-white cursor-pointer rounded-6 text-body1-medium text-text-light-sub'
                onClick={nextContentPage}
              >
                <p>다음</p>
                <button>
                  <img src={ArrowRight} alt='' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {studentBulkRegistration && (
        <BulkRegistrationModal
          setStudentBulkRegistration={setStudentBulkRegistration}
          studentCreateManyApi={studentCreateManyApi}
        />
      )}
      {studentIndividualRegistration && (
        <IndividualRegistrationModal
          setStudentIndividualRegistration={setStudentIndividualRegistration}
          studentCreateApi={studentCreateApi}
        />
      )}
      {deleteManyStudent && (
        <ClassroomDeleteManyStudentModal
          studentNum={studentNum}
          setDeleteManyStudent={setDeleteManyStudent}
          studentDeleteManyApi={studentDeleteManyApi}
        />
      )}
    </>
  )
}
