import { atom } from 'recoil'

const electricDurationState = atom({
  key: 'electricDurationState',
  default: {
    duration: 'week',
  },
})

export default electricDurationState
