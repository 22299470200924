import React, { useRef, useState } from 'react'

import ButtonModalClose from 'assets/images/button_modal-close.svg'
import ArrowDown from 'assets/images/arrow_down.svg'

// import FileUpload from 'assets/images/File_Upload.svg'

export default function IndividualRegistrationModal({
  setStudentIndividualRegistration,
  studentCreateApi,
}) {
  const modalBackgroundRef = useRef()

  const [studentName, setStudentName] = useState('')
  const [studentGrade, setStudentGrade] = useState('0')
  const [studentClass, setStudentClass] = useState('0')
  const [studentPhoneNum, setStudentPhoneNum] = useState('')

  const handleSubmit = async () => {
    if (studentName === '') {
      alert('이름을 입력해주세요')
      return
    }
    if (studentGrade === '0') {
      alert('학년을 선택해주세요')
      return
    }
    if (studentClass === '0') {
      alert('반을 선택해주세요')
      return
    }
    if (studentPhoneNum === '') {
      alert('연락처를 입력해주세요')
      return
    }
    const body = {
      name: studentName,
      grade: parseInt(studentGrade),
      classNo: parseInt(studentClass),
      phoneNumber: studentPhoneNum,
    }
    await studentCreateApi(body)
    setStudentIndividualRegistration(false)
  }
  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* 모달 배경 */}
      <div
        className='absolute w-full h-full bg-black/60'
        ref={modalBackgroundRef}
        onClick={(e) => {
          setStudentIndividualRegistration(false)
        }}
      ></div>
      {/* 모달 */}
      <div className='absolute min-w-[600px] min-h-[350px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-16'>
        <div className='flex flex-col items-center justify-center gap-20 px-40 py-40'>
          {/* 학생 등록하기 */}
          <div className='relative flex flex-col items-center justify-center w-full gap-4'>
            <p className='mb-4 text-h1-semibold text-text-light'>학생 등록하기</p>
            <div>
              <img
                src={ButtonModalClose}
                alt=''
                className='absolute top-[3px] right-0 cursor-pointer'
                onClick={() => setStudentIndividualRegistration(false)}
              />
            </div>
          </div>

          {/* 이름/학년/연락처 */}
          <div className='flex flex-col w-full gap-24'>
            {/* 이름 */}
            <div className='flex items-center gap-20'>
              <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>이름</p>
              <input
                type='text'
                onChange={(e) => setStudentName(e.target.value)}
                placeholder='이름을 입력하세요'
                className='w-full p-16 border border-border-light text-h2-medium text-text-light placeholder:text-text-light-info rounded-8'
              />
            </div>

            {/* 학년/반 */}
            <div className='flex gap-16'>
              <div className='flex items-center gap-20 basis-1/2'>
                <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>학년</p>
                <div className='w-full border border-border-light rounded-8'>
                  <select
                    onChange={(e) => setStudentGrade(e.target.value)}
                    className={`w-full px-16 py-16 bg-no-repeat bg-[center_right] text-h2-medium ${
                      studentGrade === '0' ? 'text-text-light-info' : 'text-text-light'
                    }`}
                    style={{ backgroundImage: 'url(' + ArrowDown + ')' }}
                  >
                    <option value='0' selected disabled>
                      학년을 선택하세요
                    </option>
                    <option value='1'>1학년</option>
                    <option value='2'>2학년</option>
                    <option value='3'>3학년</option>
                  </select>
                </div>
              </div>

              <div className='flex items-center gap-20 basis-1/2'>
                <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>시간</p>
                <div className='w-full border border-border-light rounded-8'>
                  <select
                    onChange={(e) => setStudentClass(e.target.value)}
                    className={`w-full px-16 py-16 bg-no-repeat bg-[center_right] text-h2-medium text-text-light ${
                      studentClass === '0' ? 'text-text-light-info' : 'text-text-light'
                    }`}
                    style={{ backgroundImage: 'url(' + ArrowDown + ')' }}
                  >
                    <option value='0' selected disabled>
                      반을 선택해주세요
                    </option>
                    <option value='1'>1반</option>
                    <option value='2'>2반</option>
                    <option value='3'>3반</option>
                    <option value='4'>4반</option>
                    <option value='5'>5반</option>
                    <option value='6'>6반</option>
                    <option value='7'>7반</option>
                  </select>
                </div>
              </div>
            </div>

            {/* 연락처 */}
            <div className='flex items-center gap-20'>
              <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>연락처</p>
              <input
                onChange={(e) => setStudentPhoneNum(e.target.value)}
                type='text'
                placeholder='연락처를 입력하세요'
                className='w-full p-16 border border-border-light text-h2-medium text-text-light placeholder:text-text-light-info rounded-8'
              />
            </div>
          </div>

          {/* 취소/등록 */}
          <div className='flex w-full '>
            <button
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center cursor-pointer select-none basis-1/2 text-h2-semibold text-text-light'
              onClick={() => setStudentIndividualRegistration(false)}
            >
              취소
            </button>
            <button
              onClick={handleSubmit}
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center  cursor-pointer select-none basis-1/2 text-h2-semibold text-primary'
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
