import React, { useRef } from 'react'
import * as user from 'apis/user.ts'
import { useNavigate } from 'react-router-dom'

export default function LogoutModal({ logoutModalOpen, setLogoutModalOpen }) {
  const modalBackground = useRef()
  const navigate = useNavigate()

  const logout = async () => {
    let logoutRes = await user.logout()
    if (!logoutRes.error) navigate('/login', { replace: true })
  }

  return (
    <div className='w-screen h-screen fixed top-0 left-0 z-50 cursor-default'>
      {/* 모달 배경 */}
      <div
        className='absolute w-full h-full bg-black/70 '
        ref={modalBackground}
        onClick={(e) => {
          if (e.target === modalBackground.current) setLogoutModalOpen((prev) => !prev)
        }}
      />
      {/* 모달 */}
      <div className='absolute min-w-[360px] min-h-[180px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-16 '>
        <div className='flex flex-col p-40 gap-48'>
          <div className='flex flex-col items-center'>
            <p className='text-h1-semibold text-text-light'>로그아웃</p>
            <p className='text-body1-medium text-text-light-sub'>로그아웃 하시겠습니까?</p>
          </div>
          <div className='flex justify-center items-center'>
            <div
              className='basis-1/2 flex justify-center items-center py-[11px] cursor-pointer'
              onClick={() => setLogoutModalOpen((prev) => !prev)}
            >
              <p className='text-h2-semibold text-text-light'>취소</p>
            </div>
            <div
              className='basis-1/2 flex justify-center items-center py-[11px] cursor-pointer'
              onClick={logout}
            >
              <p className='text-h2-semibold text-primary'>로그아웃</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
