import { CommonResponse, get, post } from './common.ts'

// (01)

/**
 * user/login
 */
interface LoginRequest {
  organizationId: string
  id: string
  password: string
}
export const login = async (body: LoginRequest): Promise<CommonResponse<null>> => {
  return await post('user/login', body)
}

/**
 * user/logout
 */
export const logout = async (): Promise<CommonResponse<null>> => {
  return await get('user/logout')
}

/**
 * user/profile
 */
interface ProfileResponse {
  organizationId: string
  id: string
  name: string
}
export const profile = async (): Promise<CommonResponse<ProfileResponse>> => {
  return await get('user/profile')
}
