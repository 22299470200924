import React, { useRef } from 'react'

import IconModalDelete from 'assets/images/icon_modal-delete.svg'

export default function ClassroomDeleteModal({
  deleteClassroomId,
  deleteClassroomName,
  setDeleteClassroomName,
  delApi,
  getListApi,
}) {
  const modalBackgroundRef = useRef()

  const handleSubmit = async () => {
    await delApi({ lectureId: deleteClassroomId })
    await getListApi()
    setDeleteClassroomName('')
  }
  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* 모달 배경 */}
      <div
        className='absolute w-full h-full bg-black/10'
        ref={modalBackgroundRef}
        onClick={(e) => {
          if (e.target === modalBackgroundRef.current) setDeleteClassroomName('')
        }}
      ></div>
      {/* 모달 */}
      <div className='absolute min-w-[400px] min-h-[291px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-16'>
        <div className='flex flex-col items-center justify-center gap-48 px-40 py-40'>
          <div className='flex flex-col items-center justify-center gap-4'>
            <div className='mb-8'>
              <img src={IconModalDelete} alt='' className='w-[20px] h-[20px]' />
            </div>
            <p className='mb-4 text-h1-semibold text-text-light'>수업 삭제</p>
            <p className='text-center text-body1-long-medium text-text-light-sub'>
              선택하신 <span className='text-danger-text'>[{deleteClassroomName}]</span>
              <br />
              수업을 삭제 하시겠습니까?
            </p>
          </div>

          <div className='flex w-full text-center'>
            <button
              className='cursor-pointer select-none basis-1/2 text-h2-semibold text-text-light'
              onClick={() => setDeleteClassroomName('')}
            >
              취소
            </button>
            <button
              onClick={handleSubmit}
              className='cursor-pointer select-none basis-1/2 text-h2-semibold text-danger-text'
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
