import React, { useEffect, useRef, useState } from 'react'
import Calendar from 'assets/logos/calendar.svg'
import Chart from 'assets/logos/chart.svg'
import { motion } from 'framer-motion'

import Lottie from 'lottie-react'
import chargingAnimation from 'assets/lotties/Animation_charging'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import loadingState from 'states/loadingState.ts'
import * as device from 'apis/device.ts'
import { minutesToHourAndMinutes } from 'apis/common.ts'
import OffSvg from 'assets/images/off.svg'
import { login as loginApi } from 'apis/board.ts'

export default function Home() {
  const setLoading = useSetRecoilState(loadingState)

  const [passwordDigit1, setPasswordDigit1] = useState('')
  const [passwordDigit2, setPasswordDigit2] = useState('')
  const [passwordDigit3, setPasswordDigit3] = useState('')
  const [passwordDigit4, setPasswordDigit4] = useState('')

  const passwordDigit1Ref = useRef(null)
  const passwordDigit2Ref = useRef(null)
  const passwordDigit3Ref = useRef(null)
  const passwordDigit4Ref = useRef(null)

  const navigate = useNavigate()

  const passwordDigit1Change = (val) => {
    if (val.length > 0) {
      passwordDigit2Ref.current.focus()
    }
  }

  const passwordDigit2Change = (val) => {
    if (val.length > 0) {
      passwordDigit3Ref.current.focus()
    } else {
      passwordDigit1Ref.current.focus()
    }
  }

  const passwordDigit3Change = (val) => {
    if (val.length > 0) {
      passwordDigit4Ref.current.focus()
    } else {
      passwordDigit2Ref.current.focus()
    }
  }

  const passwordDigit4Change = (val) => {
    if (val.length > 0) {
    } else {
      passwordDigit3Ref.current.focus()
    }
  }

  const clearPassword = () => {
    setPasswordDigit1('')
    setPasswordDigit2('')
    setPasswordDigit3('')
    setPasswordDigit4('')
  }

  const login = async () => {
    if (
      passwordDigit1 === '' ||
      passwordDigit2 === '' ||
      passwordDigit3 === '' ||
      passwordDigit4 === ''
    ) {
      return
    }
    setLoading({ isLoading: true, message: '전자칠판에 로그인하고 있습니다...' })
    let loginRes = await loginApi(passwordDigit1 + passwordDigit2 + passwordDigit3 + passwordDigit4)
    if (loginRes.error) {
      alert(loginRes.message)
    }

    setLoading({ isLoading: false })
    clearPassword()
  }

  const linkList = [
    {
      path: Calendar,
      onClick: () => {
        navigate('/classroom')
      },
      backgroundColor: '#E5EEFF',
      detail: `수업방
바로가기`,
    },
    {
      path: Chart,
      onClick: () => {
        navigate('/noticeboard')
      },
      backgroundColor: '#FFF7CA',
      detail: `디지털 알림판
관리 바로가기`,
    },
  ]

  const [devices, setDevices] = useState([])

  async function getDevices() {
    let devicesRes = await device.list('test')
    if (!devicesRes.error) {
      setDevices(devicesRes.data.devices)
    }
  }

  useEffect(() => {
    getDevices()
  }, [])

  const off = async (deviceId) => {
    setLoading({ isLoading: true, message: '기기를 종료하고 있습니다...' })
    await device.off(deviceId)
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 2000)
    })
    await getDevices()
    setLoading({ isLoading: false })
  }

  return (
    <div className='overflow-y-auto h-screen px-[60px] py-[60px] grow bg-background-light-alternative'>
      <div className='flex flex-col items-center justify-center gap-48'>
        {/* 회원 정보 */}
        <div className='flex w-full select-none'>
          <div className='flex flex-col gap-6'>
            <p className='text-title3-bold text-[#000000]'>👋홍길동님 안녕하세요!</p>
            <p className='text-body2-medium text-text-light-sub'>안산국제비즈니스고등학교</p>
          </div>
          <div className='flex justify-end grow'>
            <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              src={require('assets/images/image.png')}
              alt=''
              className='w-[50px] h-[50px] rounded-full cursor-pointer'
            />
          </div>
        </div>

        {/* 바로가기 */}
        <div className='grid w-full grid-cols-2 gap-20'>
          <div className='flex flex-col w-full'>
            <div className='min-h-[70px] flex items-center px-20 py-12 bg-blue-100 rounded-t-8 text-title3-bold text-[#000000] select-none'>
              <p>바로가기</p>
            </div>
            <div className='px-20 py-40 bg-[#FFFFFF] border border-border-light rounded-b-8'>
              <div className='flex gap-28'>
                {linkList.map((link, index) => (
                  <motion.div
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`w-[170px] cursor-pointer h-[170px] flex flex-col gap-[19px] px-[18px] py-24 rounded-12`}
                    onClick={link.onClick}
                    style={{ backgroundColor: link.backgroundColor }}
                  >
                    <img src={link.path} alt='' className='w-[50px] h-[50px]' />
                    <div>
                      <p className='whitespace-pre select-none text-h2-semibold'>{link.detail}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>

          {/* 전자칠판 로그인 */}
          <div className='flex flex-col w-full'>
            <div className='min-h-[70px] flex items-center px-20 py-12 bg-blue-100 rounded-t-8 text-title3-bold text-[#000000] select-none'>
              <p>전자칠판 로그인</p>
            </div>
            <div className='px-20 py-40 bg-[#FFFFFF] border border-border-light rounded-b-8'>
              <div className='flex flex-col items-center justify-center gap-28'>
                <div className='flex flex-col justify-center gap-8'>
                  <p className='text-center select-none text-body2-long-medium text-text-light-info'>
                    전자칠판에 표시된 4자리 코드를 입력해주세요
                  </p>
                  <div className='min-w-[310px] flex gap-8 justify-center'>
                    <label className='cursor-pointer flex justify-center items-center w-[65px] border border-[#46474C] rounded-6 pt-[17px] pb-[16px]'>
                      <input
                        type='text'
                        id='password'
                        autoComplete='off'
                        value={passwordDigit1}
                        maxLength={1}
                        ref={passwordDigit1Ref}
                        onChange={(e) => {
                          setPasswordDigit1(e.target.value)
                          passwordDigit1Change(e.target.value)
                        }}
                        className='w-[13px] text-title3-bold text-text-light caret-transparent cursor-pointer'
                      />
                    </label>
                    <label className=' cursor-pointer flex justify-center items-center w-[65px] border border-[#46474C] rounded-6 pt-[17px] pb-[16px]'>
                      <input
                        type='text'
                        id='password'
                        autoComplete='off'
                        value={passwordDigit2}
                        maxLength={1}
                        ref={passwordDigit2Ref}
                        onChange={(e) => {
                          setPasswordDigit2(e.target.value)
                          passwordDigit2Change(e.target.value)
                        }}
                        className='w-[13px] text-title3-bold text-text-light caret-transparent cursor-pointer'
                      />
                    </label>
                    <label className=' cursor-pointer flex justify-center items-center w-[65px] border border-[#46474C] rounded-6 pt-[17px] pb-[16px]'>
                      <input
                        type='text'
                        id='password'
                        autoComplete='off'
                        maxLength={1}
                        onChange={(e) => {
                          setPasswordDigit3(e.target.value)
                          passwordDigit3Change(e.target.value)
                        }}
                        value={passwordDigit3}
                        ref={passwordDigit3Ref}
                        className='w-[13px] text-title3-bold text-text-light caret-transparent cursor-pointer'
                      />
                    </label>
                    <label className=' cursor-pointer flex justify-center items-center w-[65px] border border-[#46474C] rounded-6 pt-[17px] pb-[16px]'>
                      <input
                        type='text'
                        id='password'
                        autoComplete='off'
                        maxLength={1}
                        onChange={(e) => {
                          setPasswordDigit4(e.target.value)
                          passwordDigit4Change(e.target.value)
                        }}
                        value={passwordDigit4}
                        ref={passwordDigit4Ref}
                        className='w-[13px] text-title3-bold text-text-light caret-transparent cursor-pointer'
                      />
                    </label>
                  </div>
                </div>
                <div className='min-w-[310px] flex gap-8'>
                  <div
                    className='basis-1/2 pt-[11px] pb-[10px] border border-primary rounded-6 text-center select-none cursor-pointer'
                    onClick={clearPassword}
                  >
                    <p className='text-body1-semibold text-primary'>취소</p>
                  </div>
                  <div
                    className='basis-1/2 pt-[11px] pb-[10px] bg-primary rounded-6 text-center select-none cursor-pointer'
                    onClick={login}
                  >
                    <p className='text-white text-body1-semibold'>확인</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 우리 반 전자칠판 */}
          {devices.map((device, index) => {
            return (
              <div key={index} className='flex flex-col w-full'>
                <div className='min-h-[70px] flex px-20 py-12 bg-blue-100 rounded-t-8 items-center'>
                  <p className='text-title3-bold text-[#000000] select-none'>{device.name}</p>
                  <div className='flex items-center justify-end grow'>
                    {device.status === 1 && (
                      <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className='bg-danger-base rounded-6 pt-[11px] pb-[10px] pl-[47px] pr-[48px] text-body1-semibold text-white select-none cursor-pointer'
                        onClick={() => {
                          off(device.id)
                        }}
                      >
                        <p>원격종료</p>
                      </motion.div>
                    )}
                    {device.status === 0 && (
                      <div className='bg-neutral-300 rounded-6 pt-[11px] pb-[10px] pl-[47px] pr-[48px] text-body1-semibold text-white select-none'>
                        <p>종료됨</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className='px-20 py-40 bg-[#FFFFFF] border border-border-light rounded-b-8'>
                  <div className='flex flex-col items-center justify-center'>
                    {device.status === 1 && (
                      <Lottie
                        animationData={chargingAnimation}
                        style={{ width: '200px', height: '100px' }}
                        speed={1}
                      ></Lottie>
                    )}
                    {device.status === 0 && <img src={OffSvg} alt='' className='scale-110' />}
                    <div className='select-none text-title2-bold text-text-light'>
                      <p>
                        {minutesToHourAndMinutes(device.todayUseMinutes)}{' '}
                        {device.status === 1 ? '사용 중' : '사용함'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
