import React, { useRef, useState } from 'react'

import ButtonModalClose from 'assets/images/button_modal-close.svg'
import ArrowDown from 'assets/images/arrow_down.svg'

export default function ClassroomAddModal({
  addClassroom,
  setAddClassroom,
  createApi,
  getListApi,
}) {
  const modalBackgroundRef = useRef()

  const [subjectName, setSubjectName] = useState('') // 과목명
  const [classDayOfWeek, setClassDayOfWeek] = useState('0') // 요일
  const [classTimeStart, setClassTimeStart] = useState('0') // 시간

  const handleSubmit = async () => {
    if (subjectName === '') {
      alert('과목명을 입력해주세요')
      return
    }
    if (classDayOfWeek === '0') {
      alert('요일을 선택해주세요')
      return
    }
    if (classTimeStart === '0') {
      alert('시간을 선택해주세요')
      return
    }
    const body = {
      name: subjectName,
      timetable: {
        items: [
          {
            dayOfWeek: classDayOfWeek,
            startTime: classTimeStart,
            endTime: classTimeStart,
          },
        ],
      },
    }
    await createApi(body)
    await getListApi()
    setAddClassroom(false)
  }
  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* 모달 배경 */}
      <div
        className='absolute w-full h-full bg-black/60'
        ref={modalBackgroundRef}
        onClick={(e) => {
          setAddClassroom(false)
        }}
      ></div>
      {/* 모달 */}
      <div className='absolute min-w-[600px] min-h-[350px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-16'>
        <div className='flex flex-col items-center justify-center gap-20 px-40 py-40'>
          {/* 수업 추가하기 */}
          <div className='relative flex flex-col items-center justify-center w-full gap-4'>
            <p className='mb-4 text-h1-semibold text-text-light'>수업 추가하기</p>
            <div>
              <img
                src={ButtonModalClose}
                alt=''
                className='absolute top-[3px] right-0 cursor-pointer'
                onClick={() => setAddClassroom(false)}
              />
            </div>
          </div>

          {/* 과목명/요일/시간 */}
          <div className='flex flex-col w-full gap-24'>
            <div className='flex items-center gap-20'>
              <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>과목명</p>
              <input
                type='text'
                onChange={(e) => setSubjectName(e.target.value)}
                placeholder='과목명을 입력하세요'
                className='w-full p-16 border border-border-light text-h2-medium text-text-light placeholder:text-text-light-info rounded-8'
              />
            </div>
            <div className='flex flex-wrap gap-24'>
              <div className='flex items-center w-full gap-20'>
                <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>요일</p>
                <div className='w-full border border-border-light rounded-8'>
                  <select
                    onChange={(e) => setClassDayOfWeek(e.target.value)}
                    className={`w-full px-16 py-16 bg-no-repeat bg-[center_right] text-h2-medium ${
                      classDayOfWeek === '0' ? 'text-text-light-info' : 'text-text-light'
                    }`}
                    style={{ backgroundImage: 'url(' + ArrowDown + ')' }}
                  >
                    <option value='0' selected disabled>
                      요일을 선택하세요
                    </option>
                    <option value='월요일'>월요일</option>
                    <option value='화요일'>화요일</option>
                    <option value='수요일'>수요일</option>
                    <option value='목요일'>목요일</option>
                    <option value='금요일'>금요일</option>
                    <option value='토요일'>토요일</option>
                    <option value='일요일'>일요일</option>
                  </select>
                </div>
              </div>

              <div className='flex items-center w-full gap-20'>
                <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>시간</p>
                <div className='w-full border border-border-light rounded-8'>
                  <select
                    onChange={(e) => setClassTimeStart(e.target.value)}
                    className={`w-full px-16 py-16 bg-no-repeat bg-[center_right] text-h2-medium text-text-light ${
                      classTimeStart === '0' ? 'text-text-light-info' : 'text-text-light'
                    }`}
                    style={{ backgroundImage: 'url(' + ArrowDown + ')' }}
                  >
                    <option value='0' selected disabled>
                      시간을 선택하세요
                    </option>
                    <option value='1교시 (9시 00분 ~ 9시 50분)'>1교시 (9시 00분 ~ 9시 50분)</option>
                    <option value='2교시 (10시 00분 ~ 10시 50분)'>
                      2교시 (10시 00분 ~ 10시 50분)
                    </option>
                    <option value='3교시 (11시 00분 ~ 11시 50분)'>
                      3교시 (11시 00분 ~ 11시 50분)
                    </option>
                    <option value='4교시 (12시 00분 ~ 12시 50분)'>
                      4교시 (12시 00분 ~ 12시 50분)
                    </option>
                    <option value='5교시 (14시 00분 ~ 14시 50분)'>
                      5교시 (14시 00분 ~ 14시 50분)
                    </option>
                    <option value='6교시 (15시 00분 ~ 15시 50분)'>
                      6교시 (15시 00분 ~ 15시 50분)
                    </option>
                    <option value='7교시 (16시 00분 ~ 16시 50분)'>
                      7교시 (16시 00분 ~ 16시 50분)
                    </option>
                    <option value='8교시 (17시 00분 ~ 17시 50분)'>
                      8교시 (17시 00분 ~ 17시 50분)
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* 취소/등록 */}
          <div className='flex w-full '>
            <button
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center cursor-pointer select-none basis-1/2 text-h2-semibold text-text-light'
              onClick={() => setAddClassroom(false)}
            >
              취소
            </button>
            <button
              onClick={handleSubmit}
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center  cursor-pointer select-none basis-1/2 text-h2-semibold text-primary'
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
