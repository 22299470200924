import { TLDRAW_WORKER_URL } from 'constants/settings.ts'
import { TLAssetStore, uniqueId } from 'tldraw'

// How does our server handle assets like images and videos?
export const multiplayerAssetStore: TLAssetStore = {
  // to upload an asset, we...
  async upload(_asset, file) {
    // ...create a unique name & URL...
    const id = uniqueId()
    const objectName = `${id}-${file.name}`.replace(/[^a-zA-Z0-9.]/g, '-')
    const url = `${TLDRAW_WORKER_URL}/uploads/${objectName}`

    // ...POST it to out worker to upload it...
    const response = await fetch(url, {
      method: 'POST',
      body: file,
    })

    if (!response.ok) {
      throw new Error(`Failed to upload asset: ${response.statusText}`)
    }

    // ...and return the URL to be stored with the asset record.
    return url
  },

  // to retrieve an asset, we can just use the same URL. you could customize this to add extra
  // auth, or to serve optimized versions / sizes of the asset.
  resolve(asset) {
    return asset.props.src
  },
}
