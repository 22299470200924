import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Legend)

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        font: {
          size: 14,
        },
      },
    },
    title: {
      display: true,
      text: 'electricboard',
    },
  },
}

export default function LineChart({ graphData }) {
  return <Line options={options} data={graphData} />
}
