import React, { useState } from 'react'
import LogoLogin from 'assets/logos/logo-login.svg'
import LogoLoginTransparent from 'assets/logos/logo-login-transparent.svg'
import * as user from 'apis/user.ts'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  const login = async () => {
    if (id === '') {
      alert('아이디를 입력해주세요')
      return
    }

    if (password === '') {
      alert('비밀번호를 입력해주세요')
      return
    }

    let loginRes = await user.login({
      id: id,
      password: password,
      organizationId: 'test',
    })
    if (loginRes.error) {
      alert(loginRes.message)
      return
    }

    navigate('/', { replace: true })
  }

  return (
    <div className='flex'>
      {/* <div
        className='h-screen w-[1000px] bg-no-repeat'
        style={{ backgroundImage: 'url(' + require('assets/images/login-background.png') + ')' }}
      ></div> */}
      <div className='w-full relative'>
        <img
          src={require('assets/images/login-background.png')}
          alt=''
          className='w-full h-screen'
        />
        <div className='flex flex-col justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-20'>
          <img src={LogoLoginTransparent} alt='' />
          <p className='text-white text-[68.492px] font-semibold leading-[68.492px] tracking-[-2.74px]'>
            Edu LMS
          </p>
        </div>
      </div>
      <div className='w-full bg-[#F7F7F8]  flex justify-center items-center'>
        <div className='flex flex-col gap-[50px]'>
          {/* Edu LMS */}
          <div className='flex justify-center items-center'>
            <img src={LogoLogin} alt='' className='mr-[7.96px]' />
            <p className='text-[49.732px] font-semibold leading-[49.732px] tracking-[-1.989px]'>
              Edu LMS
            </p>
          </div>

          {/* id/pw */}
          <div className='flex flex-col gap-20'>
            <div className='flex flex-col gap-12'>
              <label htmlFor='id' className='text-body1-semibold select-none'>
                아이디
              </label>
              <input
                type='text'
                value={id}
                onChange={(e) => setId(e.target.value)}
                id='id'
                placeholder='아이디를 입력해주세요'
                className='w-[400px] h-[60px] rounded-10 pl-20 py-[18px]'
              />
            </div>

            <div className='flex flex-col gap-12'>
              <label htmlFor='password' className='text-body1-semibold select-none'>
                비밀번호
              </label>
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id='password'
                placeholder='비밀번호를 입력해주세요'
                className='w-[400px] h-[60px] rounded-10 pl-20 py-[18px]'
              />
            </div>
          </div>

          {/* login */}
          <div className='rounded-10 bg-primary text-center cursor-pointer' onClick={login}>
            <p className='px-80 py-16 text-white text-h2-semibold'>로그인</p>
          </div>
        </div>
      </div>
    </div>
  )
}
