import React, { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import chargingAnimation from 'assets/lotties/Animation_charging'
import NoticeGrid from 'components/NoticeGrid'
import { motion } from 'framer-motion'
import LineChart from 'components/LineChart'

import * as deviceAPI from 'apis/device.ts'
import loadingState from 'states/loadingState.ts'
import OffSvg from 'assets/images/off.svg'
import { minutesToHourAndMinutes } from 'apis/common.ts'
import { useSetRecoilState } from 'recoil'

export default function NoticeBoard() {
  const setLoading = useSetRecoilState(loadingState)

  const [device, setDevice] = useState(null)
  const [graph, setGraph] = useState(null)
  const [todayUseMinutes, setTodayUseMinutes] = useState(0)

  const [reRender, setReRender] = useState(0)

  const handleResize = () => {
    // console.log(reRender)
    setReRender((prev) => prev + 1)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      // cleanup
      window.removeEventListener('resize', handleResize)
    }
  })

  async function getDeviceDetail() {
    let detailRes = await deviceAPI.detail('board2')
    if (!detailRes.error) {
      setDevice(detailRes.data.device)
      setGraph(detailRes.data.graph)
      setTodayUseMinutes(detailRes.data.todayUseMinutes)
    }
  }

  useEffect(() => {
    getDeviceDetail()
  }, [])

  const off = async () => {
    setLoading({ isLoading: true, message: '기기를 종료하고 있습니다...' })
    await deviceAPI.off('board2')
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 2000)
    })
    await getDeviceDetail()
    setLoading({ isLoading: false })
  }

  return (
    <div className='overflow-y-auto h-screen px-[60px] py-[60px] grow bg-background-light-alternative'>
      <div className='flex flex-col gap-20'>
        {/* 우리 반 디지털 교실 알림판 관리 */}
        {device && (
          <div className='flex flex-col w-full'>
            <div className='box-header'>
              <p className='box-header-title'>우리 반 디지털 교실 알림판 관리</p>
              <div className='flex items-center justify-end grow'>
                {device.status === 1 && (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className='button-quit'
                    onClick={() => {
                      off()
                    }}
                  >
                    <p>원격종료</p>
                  </motion.button>
                )}
                {device.status === 0 && (
                  <div className='bg-neutral-300 rounded-6 pt-[11px] pb-[10px] pl-[47px] pr-[48px] text-body1-semibold text-white select-none cursor-pointer'>
                    <p>종료됨</p>
                  </div>
                )}
              </div>
            </div>
            <div className='box-body select-none'>
              <div className='flex flex-col items-center justify-center gap-4'>
                <p className='text-body1-semibold text-primary'>Today</p>
                <p className='text-title3-medium text-text-light-sub'>2024.07.05 (금)</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                {device.status === 1 && (
                  <Lottie
                    animationData={chargingAnimation}
                    style={{ width: '200px', height: '100px' }}
                    speed={1}
                  ></Lottie>
                )}
                {device.status === 0 && <img src={OffSvg} alt='' className='scale-110' />}
                <div className='text-title2-bold text-text-light'>
                  <p>
                    {minutesToHourAndMinutes(todayUseMinutes)}{' '}
                    {device.status === 1 ? '사용 중' : '사용함'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* 사용시간, 알림판 */}
        <div className='grid grid-cols-2 gap-20'>
          {graph && (
            <div className='flex flex-col gap-20'>
              <div className='flex flex-col w-full'>
                <div className='box-header'>
                  <p className='box-header-title'>오늘의 사용시간</p>
                </div>
                <div className='box-body'>
                  <LineChart
                    key={reRender}
                    graphData={{
                      labels: graph.today.labels,
                      datasets: [
                        {
                          label: '전력량',
                          data: graph.today.energy,
                          backgroundColor: 'rgba(255, 99, 99, 1)',
                          borderColor: 'rgba(255, 99, 99, 1)',
                          borderWidth: 3,
                          pointRadius: 4, // 포인트 크기
                          pointBorderWidth: 2,
                        },
                        {
                          label: '사용시간',
                          data: graph.today.minutes,
                          backgroundColor: 'rgba(183, 210, 250, 1)',
                          borderColor: 'rgba(204, 224, 255, 1)',
                          borderWidth: 3,
                          pointRadius: 4, // 포인트 크기
                          pointBorderWidth: 2,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col w-full'>
                <div className='box-header'>
                  <p className='box-header-title'>이번주 사용시간</p>
                </div>
                <div className='box-body'>
                  {' '}
                  <LineChart
                    key={reRender}
                    graphData={{
                      labels: graph.week.labels,
                      datasets: [
                        {
                          label: '전력량',
                          data: graph.week.energy,
                          backgroundColor: 'rgba(255, 99, 99, 1)',
                          borderColor: 'rgba(255, 99, 99, 1)',
                          borderWidth: 3,
                          pointRadius: 4, // 포인트 크기
                          pointBorderWidth: 2,
                        },
                        {
                          label: '사용시간',
                          data: graph.week.minutes,
                          backgroundColor: 'rgba(183, 210, 250, 1)',
                          borderColor: 'rgba(204, 224, 255, 1)',
                          borderWidth: 3,
                          pointRadius: 4, // 포인트 크기
                          pointBorderWidth: 2,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {/* 알림판 Grid */}
          <NoticeGrid />
        </div>
      </div>
    </div>
  )
}
