import { atom } from 'recoil'

const loadingState = atom({
  key: 'loadingState',
  default: {
    isLoading: false,
    message: '',
  },
})

export default loadingState
