import React, { useState, useRef } from 'react'
import * as XLSX from 'xlsx'
import ButtonModalClose from 'assets/images/button_modal-close.svg'
import FileUpload from 'assets/images/File_Upload.svg'
import ExcelIcon from 'assets/images/excel.svg'

export default function BulkRegistrationModal({
  setStudentBulkRegistration,
  studentCreateManyApi,
}) {
  const modalBackgroundRef = useRef()
  const [isDragging, setIsDragging] = useState(false)
  const [contentsFile, setContentsFile] = useState(null)
  const [body, setBody] = useState([])

  const convertXlsxToJson = (file) => {
    const reader = new FileReader()

    reader.onload = function (e) {
      if (e.target) {
        // 엑셀 파일을 워크북으로 변환
        const workbook = XLSX.read(e.target.result, { type: 'binary' })
        // 시트 선택
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]

        // 워크시트를 JSON 형식으로 변환
        const json = XLSX.utils.sheet_to_json(worksheet)
        // option파라미터에 header:1을 설정해주면 2차원 배열로 변환
        // const aoa = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

        console.log(json)
        setBody(json)
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const callStudentCreateManyApi = async () => {
    if (body.length === 0) {
      alert('엑셀 파일을 업로드해주세요.')
      return
    }
    let isValidate = true
    body.forEach((student) => {
      if (!student.이름 || !student.학년 || !student.반 || !student.전화번호) {
        isValidate = false
      }
    })
    if (!isValidate) {
      alert('요효하지 않은 데이터가 포함되어 있습니다. (이름, 학년, 반, 전화번호 모두 입력)')
      return
    }
    let requestBody = []
    body.forEach((student) => {
      requestBody.push({
        name: student.이름,
        grade: student.학년,
        classNo: student.반,
        phoneNumber: student.전화번호,
      })
    })
    await studentCreateManyApi(requestBody)
    setStudentBulkRegistration(false)
  }

  const handleFileUpload = (e) => {
    if (e.target.files) {
      if (e.target.files.length === 0) {
        return
      }
      if (e.target.files[0].name.split('.').pop() !== 'xlsx') {
        alert('엑셀 파일만 업로드 가능합니다.')
        return
      }
      setContentsFile(e.target.files[0])
      convertXlsxToJson(e.target.files[0])
    }
  }
  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(true)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files) {
      setIsDragging(true)
    }
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    if (e.dataTransfer.files) {
      if (e.dataTransfer.files.length === 0) {
        return
      }
      if (e.dataTransfer.files[0].name.split('.').pop() !== 'xlsx') {
        alert('엑셀 파일만 업로드 가능합니다.')
        return
      }
      setContentsFile(e.dataTransfer.files[0])
      convertXlsxToJson(e.dataTransfer.files[0])
    }
  }

  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* 모달 배경 */}
      <div
        className='absolute w-full h-full bg-black/60'
        ref={modalBackgroundRef}
        onClick={() => setStudentBulkRegistration(false)}
      ></div>
      {/* 모달 */}
      <div className='absolute min-w-[600px] min-h-[400px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-16'>
        <div className='flex flex-col items-center justify-center gap-20 px-40 py-40'>
          {/* 학생 목록 파일 업로드 */}
          <div className='relative flex flex-col items-center justify-center w-full gap-4'>
            <p className='mb-4 text-h1-semibold text-text-light'>학생 목록 파일 업로드</p>
            <a
              href='/LMS 학생 추가 리스트(예시).xlsx'
              className='mb-4 underline underline-offset-4 text-h3-semibold text-text-light-sub hover:text-primary'
            >
              [ 템플릿 파일 다운로드 ]
            </a>
            <div>
              <img
                src={ButtonModalClose}
                alt=''
                className='absolute top-[3px] right-0 cursor-pointer'
                onClick={() => setStudentBulkRegistration(false)}
              />
            </div>
          </div>

          {/* 파일 업로드 */}
          <label>
            <input type='file' onChange={handleFileUpload} aria-hidden />
            <div
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              className={`flex items-center justify-center w-[520px] h-[200px] border-2 border-dashed ${
                isDragging ? 'border-primary-hover' : 'border-border-light-strong'
              } rounded-10 p-20`}
            >
              {contentsFile ? (
                <div className='flex flex-col items-center justify-center gap-4'>
                  <img src={ExcelIcon} alt='' className='w-[70px]' />
                  <div className='flex flex-col gap-4 text-center'>
                    <p className='text-text-light-sub text-h2-medium'>{contentsFile.name}</p>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col items-center justify-center gap-24'>
                  <img src={FileUpload} alt='' />
                  <div className='flex flex-col gap-4 text-center'>
                    <p
                      className={`text-[14px] ${
                        isDragging ? 'text-primary' : 'text-text-light-info'
                      } font-medium leading-[22px] tracking-[-0.35px]`}
                    >
                      클릭 혹은 드래그 앤드 드롭하여 콘텐츠를 업로드 해주세요
                    </p>
                    <p className='text-[12px] text-[#878A93] leading-[16px] tracking-[-0.3px]'>
                      (.xlsx 파일만 업로드 가능)
                    </p>
                  </div>
                </div>
              )}
            </div>
          </label>

          {/* 취소/업로드하기 */}
          <div className='flex w-full '>
            <button
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center cursor-pointer select-none basis-1/2 text-h2-semibold text-text-light'
              onClick={() => setStudentBulkRegistration(false)}
            >
              취소
            </button>
            <button
              onClick={callStudentCreateManyApi}
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center  cursor-pointer select-none basis-1/2 text-h2-semibold text-primary'
            >
              업로드하기
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
