import { CommonResponse, post } from './common.ts'

interface Device {
  id: string
  name: string
  type: number
  status: number
  todayUseMinutes: number
}

/**
 * device/list
 */

interface ListResponse {
  devices: Device[]
}
export const list = async (unitId: string): Promise<CommonResponse<ListResponse>> => {
  return await post('device/list', { unitId })
}

/**
 * device/detail
 */
interface GraphItem {
  labels: string[]
  energy: number[]
  minutes: string[]
}

interface DetailResponse {
  device: Device
  graph: {
    today: GraphItem
    week: GraphItem
    month: GraphItem
  }
  todayUseMinutes: number
}
export const detail = async (deviceId: string): Promise<CommonResponse<DetailResponse>> => {
  return await post(`device/detail`, { deviceId })
}

/**
 * device/off
 */
export const off = async (deviceId: string): Promise<CommonResponse<null>> => {
  return await post('device/off', { deviceId })
}
