import React from 'react'
import Lottie from 'lottie-react'
import { motion, AnimatePresence } from 'framer-motion'
import * as bookLottie from 'assets/lotties/book.json'
import { useRecoilState } from 'recoil'
import loadingState from 'states/loadingState.ts'

export default function Loader() {
  const [loading] = useRecoilState(loadingState)
  return (
    <>
      <AnimatePresence>
        {loading.isLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='absolute w-screen h-screen flex flex-col justify-center items-center bg-[#2a2c45c5] z-30'
          >
            <div className='pr-[10px] pb-[40px]'>
              <Lottie
                animationData={bookLottie}
                style={{ width: '300px', height: '300px' }}
                speed={1}
                loop={true}
                autoPlay={true}
              />
            </div>
            <div>
              <p className='text-white text-[32px] mt-[30px]'>{loading.message}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
