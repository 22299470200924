import React, { useRef, useState } from 'react'

import ButtonModalClose from 'assets/images/button_modal-close.svg'
import ArrowDown from 'assets/images/arrow_down.svg'

export default function ClassroomModifyModal({
  studentId,
  name,
  grade,
  classNum,
  phoneNum,
  setModifyStudent,
  studentEditApi,
}) {
  const modalBackgroundRef = useRef()

  const [studentName, setStudentName] = useState(name)
  const [studentGrade, setStudentGrade] = useState(grade)
  const [studentClass, setStudentClass] = useState(classNum)
  const [studentPhoneNum, setStudentPhoneNum] = useState(phoneNum)

  const handleSubmit = async () => {
    if (studentName === '') {
      alert('이름을 입력해주세요')
      return
    }
    if (studentGrade === '0') {
      alert('학년을 선택해주세요')
      return
    }
    if (studentClass === '0') {
      alert('반을 선택해주세요')
      return
    }
    if (studentPhoneNum === '') {
      alert('연락처를 입력해주세요')
      return
    }
    const body = {
      studentId: studentId,
      name: studentName,
      grade: studentGrade,
      classNo: studentClass,
      phoneNumber: studentPhoneNum,
    }
    await studentEditApi(body)
    setModifyStudent(false)
  }
  return (
    <div className='fixed top-0 left-0 z-50 w-screen h-screen cursor-default'>
      {/* 모달 배경 */}
      <div
        className='absolute w-full h-full bg-black/10'
        ref={modalBackgroundRef}
        onClick={(e) => {
          setModifyStudent(false)
        }}
      ></div>
      {/* 모달 */}
      <div className='absolute min-w-[600px] min-h-[430px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-16'>
        <div className='flex flex-col items-center justify-center gap-20 px-40 py-40'>
          {/* 수업 추가하기 */}
          <div className='relative flex flex-col items-center justify-center w-full gap-4'>
            <p className='mb-4 text-h1-semibold text-text-light'>학생 수정하기</p>
            <div>
              <img
                src={ButtonModalClose}
                alt=''
                className='absolute top-[3px] right-0 cursor-pointer'
                onClick={() => setModifyStudent(false)}
              />
            </div>
          </div>

          {/* 이름/학년/연락처 */}
          <div className='flex flex-col w-full gap-24'>
            {/* 이름 */}
            <div className='flex items-center gap-20'>
              <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>이름</p>
              <input
                onChange={(e) => {
                  setStudentName(e.target.value)
                }}
                type='text'
                value={studentName}
                className='w-full p-16 border border-border-light text-h2-medium text-text-light rounded-8'
              />
            </div>

            {/* 학년 */}
            <div className='flex gap-16'>
              <div className='flex items-center gap-20 basis-1/2'>
                <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>학년</p>
                <div className='w-full border border-border-light rounded-8'>
                  <select
                    className='w-full px-16 py-16 bg-no-repeat bg-[center_right] text-h2-medium text-text-light-info'
                    onChange={(e) => {
                      setStudentGrade(e.target.value)
                    }}
                    style={{ backgroundImage: 'url(' + ArrowDown + ')' }}
                  >
                    <option value={'0'} disabled selected>
                      {grade}학년
                    </option>
                    <option value='1'>1학년</option>
                    <option value='2'>2학년</option>
                    <option value='3'>3학년</option>
                  </select>
                </div>
              </div>

              {/* 반 */}
              <div className='flex items-center gap-20 basis-1/2'>
                <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>반</p>
                <div className='w-full border border-border-light rounded-8'>
                  <select
                    onChange={(e) => {
                      setStudentClass(e.target.value)
                    }}
                    className='w-full px-16 py-16 bg-no-repeat bg-[center_right] text-h2-medium text-text-light-info'
                    style={{ backgroundImage: 'url(' + ArrowDown + ')' }}
                  >
                    <option value={'0'} disabled selected>
                      {classNum}반
                    </option>
                    <option value='1'>1반</option>
                    <option value='2'>2반</option>
                    <option value='3'>3반</option>
                    <option value='4'>4반</option>
                    <option value='5'>5반</option>
                    <option value='6'>6반</option>
                    <option value='7'>7반</option>
                    <option value='8'>8반</option>
                  </select>
                </div>
              </div>
            </div>

            {/* 연락처 */}
            <div className='flex items-center gap-20'>
              <p className='min-w-[50px] text-end text-body1-semibold text-text-light'>연락처</p>
              <input
                type='text'
                placeholder={phoneNum}
                value={studentPhoneNum}
                onChange={(e) => {
                  setStudentPhoneNum(e.target.value)
                }}
                className='w-full p-16 border border-border-light text-h2-medium text-text-light rounded-8'
              />
            </div>
          </div>

          {/* 취소/등록 */}
          <div className='flex w-full '>
            <button
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center cursor-pointer select-none basis-1/2 text-h2-semibold text-text-light'
              onClick={() => setModifyStudent(false)}
            >
              취소
            </button>
            <button
              onClick={handleSubmit}
              className='w-full h-[45px] pt-[11px] pb-8 flex justify-center items-center  cursor-pointer select-none basis-1/2 text-h2-semibold text-primary'
            >
              수정
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
