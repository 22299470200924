import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom'

import Home from 'views/Home'
import Login from 'views/Login'
import Classroom from 'views/Classroom'
import ElectricBoard from 'views/ElectricBoard'
import NoticeBoard from 'views/NoticeBoard'
import NotFound from 'views/NotFound'
import Content from 'views/Content'
import SideBar from 'components/SideBar'
import { useRecoilState } from 'recoil'
import loginState from 'states/loginState.ts'
import * as user from 'apis/user.ts'
import { useEffect } from 'react'
import ClassroomAdmin from 'views/ClassroomAdmin'
import ClassroomDetail from 'components/ClassroomDetail'
import ClassroomAttendance from 'components/ClassroomAttendance'

function RequireAuth({ children, redirectTo }) {
  const [login, setLogin] = useRecoilState(loginState)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await user.profile()
      if (profileRes.error) {
        setLogin({ isLoggedIn: false, id: null, organizationId: null })
      } else {
        setLogin({
          isLoggedIn: true,
          id: profileRes.data.id,
          organizationId: profileRes.data.organizationId,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === true) {
    return children
  } else if (login.isLoggedIn === false) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

function NoRequireAuth({ children, redirectTo }) {
  const [login, setLogin] = useRecoilState(loginState)

  useEffect(() => {
    async function checkLogin() {
      let profileRes = await user.profile()
      if (profileRes.error) {
        setLogin({ isLoggedIn: false, id: null, organizationId: null })
      } else {
        setLogin({
          isLoggedIn: true,
          id: profileRes.data.id,
          organizationId: profileRes.data.organizationId,
        })
      }
    }

    checkLogin()
  }, [setLogin])

  if (login.isLoggedIn === false) {
    return children
  } else if (login.isLoggedIn === true) {
    return <Navigate to={redirectTo} />
  } else {
    return <></>
  }
}

export default function RootRouter() {
  return (
    <Router>
      <Routes>
        <Route
          path='/login'
          element={
            <NoRequireAuth redirectTo={'/'}>
              <Login />
            </NoRequireAuth>
          }
        />
        <Route element={<LayoutWithSidebar />}>
          <Route
            path='/'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path='/classroom/:id'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Classroom />
              </RequireAuth>
            }
          />
          <Route
            path='/electricboard'
            element={
              <RequireAuth redirectTo={'/login'}>
                <ElectricBoard />
              </RequireAuth>
            }
          />
          <Route
            path='/noticeboard'
            element={
              <RequireAuth redirectTo={'/login'}>
                <NoticeBoard />
              </RequireAuth>
            }
          />
          <Route
            path='/content'
            element={
              <RequireAuth redirectTo={'/login'}>
                <Content />
              </RequireAuth>
            }
          />
          <Route
            path='/classroomAdmin'
            element={
              <RequireAuth redirecTo={'/login'}>
                <ClassroomAdmin />
              </RequireAuth>
            }
          />
          <Route
            path='/classroomAdmin/classroomDetail/:classId'
            element={
              <RequireAuth redirectTo={'/login'}>
                <ClassroomDetail />
              </RequireAuth>
            }
          />
          <Route
            path='/classroomAdmin/attendance/:classId'
            element={
              <RequireAuth redirectTo={'/login'}>
                <ClassroomAttendance />
              </RequireAuth>
            }
          />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  )
}

function LayoutWithSidebar() {
  return (
    <div className='flex'>
      <SideBar />
      <Outlet />
    </div>
  )
}
