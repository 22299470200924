import { BASE_URL } from 'constants/settings.ts'
import { CommonResponse, get, post } from './common.ts'

interface Content {
  id: string
  name: string
  isPrivate: string
  mimeType: string
  fileSize: number
  createdAt: number
  updatedAt: number
  totalPage: number
}

/**
 * content/list
 */

interface ListResponse {
  devices: Content[]
}
export const list = async (): Promise<CommonResponse<ListResponse>> => {
  return await get('content/list')
}

/**
 * content/thumbnail
 */
export const thumbnail = (contentId: string): string => {
  return BASE_URL + 'content/thumbnail?contentId=' + contentId
}

/**
 * content/download
 */
export const download = (contentId: string): string => {
  return BASE_URL + 'content/download?contentId=' + contentId
}

/**
 * content/page
 */
export const page = (contentId: string, page: number): string => {
  return BASE_URL + 'content/page?contentId=' + contentId + '&page=' + page
}

/**
 * content/upload
 */
export const upload = async (file: File, isPrivate: boolean): Promise<CommonResponse<Content>> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('isPrivate', isPrivate ? 'true' : 'false')
  return await post('content/upload', formData)
}
